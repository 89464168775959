import { Box, Button, colors, Container, IconButton, Typography } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom'


import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react';
import { Keyboard, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


interface Ilevels {
    levels: any[]
}

export const Levels: FC<Ilevels> = ({ levels }) => {
    const [searchParams] = useSearchParams();
    const programParam = searchParams.get('p');
    const levelParam = searchParams.get('l');

    const swiperRef = useRef<any>();
    // const arrowElem = useRef<any>();

    // const swiper = useSwiper();
    // const swiperSlide = useSwiperSlide();


    return (
        <>
            {/* <Typography variant="h6" textAlign='center' mb={2}>Уровни</Typography> */}
            <Box display='flex' flex='wrap' justifyContent='center' alignItems='center'>
                <Container sx={{ pl: 0, pr: 0 }} maxWidth='md'>

                    <Swiper
                        // slidesPerView={6}
                        centeredSlides={false}
                        // slidesPerGroupSkip={1}
                        spaceBetween={30}
                        grabCursor={true}
                        // keyboard={{
                        //     enabled: true,
                        // }}
                        breakpoints={{
                            0: {
                                slidesPerView: 3.2,
                                spaceBetween: 5
                            },
                            768: {
                                slidesPerView: 4.5,
                                spaceBetween: 10
                            },
                            1536: {
                                slidesPerView: 6,
                                spaceBetween: 15
                            }

                        }}
                        scrollbar={true}
                        // navigation={true}
                        // pagination={{
                        //     clickable: true,
                        // }}
                        onBeforeInit={(swiper: any) => {
                            swiperRef.current = swiper;
                        }}
                        modules={[Keyboard, Navigation]}
                        className={`mySwiper`}
                        style={{ position: "relative" }}
                    >

                        {levels && levels.map((e, i) => {
                            return <SwiperSlide key={i} style={{ borderRadius: 0 }}>
                                <Button component={NavLink}
                                    sx={{
                                        mr: 1,
                                        width: "100%"
                                    }}
                                    to={`?p=${programParam}&l=${e.summ}&m=0`} variant={Number(levelParam) === Number(e.summ) ? "contained" : `outlined`}>
                                    <Box display='flex' flexDirection='column' alignItems='center'>
                                        <Typography textAlign='center' component='p' sx={{ fontWeight: 500 }}>
                                            {e.title}
                                        </Typography>
                                        <Typography textAlign='center' component='p'>
                                            {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(e.summ)}
                                        </Typography>
                                    </Box>
                                </Button>
                            </SwiperSlide>
                        })}

                        <IconButton
                            onClick={() => swiperRef.current?.slidePrev()}
                            sx={{
                                display: { xs: "inline-block", xl: "none" },
                                background: '#8487c575',
                                width: "44px",
                                mr: 2,
                                height: "44px",
                                position: "absolute",
                                top: '10%',
                                left: 0,
                                zIndex: "555",
                                "&:hover": {
                                    background: '#8c8c8c96',
                                }
                            }}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="14"
                                fill="none"
                                viewBox="0 0 7 14"
                            >
                                <path
                                    stroke="#151ee9"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                    d="M6 13.002l-3.879-3.88a3 3 0 010-4.242L6 1.001"
                                ></path>
                            </svg>
                        </IconButton>

                        <IconButton
                            onClick={() => swiperRef.current?.slideNext()}
                            sx={{
                                display: { xs: "inline-block", xl: "none" },
                                background: '#8487c575',
                                width: "44px",
                                height: "44px",
                                position: "absolute",
                                top: '10%',
                                right: 0,
                                zIndex: "555",
                                "&:hover": {
                                    background: '#8c8c8c96',
                                }
                            }}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="15"
                                fill="none"
                                viewBox="0 0 7 15"
                            >
                                <path
                                    stroke="#151ee9"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                    d="M1 1.775l3.879 3.879a3 3 0 010 4.242L1 13.775"
                                ></path>
                            </svg>
                        </IconButton>
                    </Swiper >

                </Container>

            </Box>
        </>
    )
}

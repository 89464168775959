import { useEffect, useState } from 'react';
import { CardContent, Grid, Typography, Box, Button, Stack, TextField } from '@mui/material';

// components
import BlankCard from '../../shared/BlankCard';
import CustomFormLabel from '../../forms/theme-elements/CustomFormLabel';
// import CustomSelect from '../../forms/theme-elements/CustomSelect';

// images
import { useSelector } from 'react-redux';
import { AppState } from 'src/store/Store';
import { useForm, } from 'react-hook-form';
import { useUpdUserDataMutation } from 'src/redux/api/cabinet';
import { useDispatch } from 'react-redux';
import { updProfileData } from 'src/redux/slices/app';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UpdatePasswordBlock } from './UpdatePasswordBlock';
import { UpdateAvatar } from './UpdateAvatar';
import { Alerting } from 'src/views/components_cabinet/Alerting';

const AccountTab = () => {
  const { data } = useSelector((state: AppState) => state.app);
  const dispatch = useDispatch();
  const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    userUpdateRequest(data);
  }

  const [userUpdateData, { isLoading: loadUpdateData }] = useUpdUserDataMutation();
  const userUpdateRequest = async (body: any) => {
    const jwt = localStorage.getItem('jwt');
    await userUpdateData({ body, jwt })
      .unwrap()
      .then((payload: any) => {
        // console.log('userUpdateData');
        // console.log(payload);
        dispatch(updProfileData(payload.data));
        setgetAlert({ type: 1, msg: payload.message });
      })
      .catch((error: any) => {
        // console.log('err userUpdateData');
        // console.log(error);

        setgetAlert({ type: 2, msg: error.data.message });
      })
  }


  useEffect(() => {
    data && setValue('first_name', data.first_name);
    data && setValue('last_name', data.last_name);
    data && setValue('country', data.country);
    data && setValue('city', data.city);
    data && setValue('phone', data.phone);
    data && setValue('telegram', data.telegram);
    data && setValue('vk', data.vk);
    data && setValue('whatsapp', data.whatsapp);
  }, [data]);

  return (
    <>
      {getAlert && <Alerting get={getAlert} />}
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <BlankCard>
            <CardContent>
              <Typography variant="h5" mb={1}>
                Редактировать профиль {data && data.login ? data.login : ''}
              </Typography>
              <Typography color="textSecondary" mb={3}>Измените фотографию своего профиля здесь</Typography>
              <Box textAlign="center" display="flex" justifyContent="center">
                <UpdateAvatar />
              </Box>
            </CardContent>
          </BlankCard>
        </Grid>
        {/*  Change Password */}
        <Grid item xs={12} lg={6}>
          <UpdatePasswordBlock />
        </Grid>

        <Grid item xs={12}>
          <BlankCard>
            <CardContent>
              <Typography variant="h5" mb={1}>
                Данные профиля
              </Typography>
              <Typography color="textSecondary" mb={3}>
                Изменить свои личные данные можно здесь
              </Typography>
              <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="text-name"
                    >
                      Ваше имя
                    </CustomFormLabel>
                    <TextField
                      // value={data ? data.first_name : ''}
                      variant="outlined"
                      fullWidth
                      error={errors.first_name ? true : false}
                      helperText={errors.first_name ? `${errors.first_name.message}` : ``}
                      {...register("first_name", {
                        required: { value: true, message: "Поле имя обязательно для заполнения" },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="text-store-name"
                    >
                      Ваша фамилия
                    </CustomFormLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      error={errors.last_name ? true : false}
                      helperText={errors.last_name ? `${errors.last_name.message}` : ``}
                      {...register("last_name", {
                        required: { value: true, message: "Поле фамилия обязательно для заполнения" },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="text-address"
                    >
                      Страна
                    </CustomFormLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      error={errors.country ? true : false}
                      helperText={errors.country ? `${errors.country.message}` : ``}
                      {...register("country", {
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="text-address"
                    >
                      Город
                    </CustomFormLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      error={errors.city ? true : false}
                      helperText={errors.city ? `${errors.city.message}` : ``}
                      {...register("city", {
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="text-email"
                    >
                      Email
                    </CustomFormLabel>
                    <TextField
                      value={data && data.email}
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="text-phone"
                    >
                      Телефон
                    </CustomFormLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      {...register("phone", {
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="text-address"
                    >
                      Ссылка на вас в Телеграм
                    </CustomFormLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      {...register("telegram", {
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="text-address"
                    >
                      Ссылка на вас в VK
                    </CustomFormLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      {...register("vk", {
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomFormLabel
                      sx={{
                        mt: 0,
                      }}
                      htmlFor="text-address"
                    >
                      Ссылка на вас в WhatsApp
                    </CustomFormLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      {...register("whatsapp", {
                      })}
                    />
                  </Grid>
                </Grid>
                <Stack direction="row" spacing={2} sx={{ justifyContent: 'end' }} mt={3}>
                  <Button type='submit' variant="contained" color="primary" size="large" disabled={loadUpdateData}>
                    {loadUpdateData && 'Загрузка...'}
                    {!loadUpdateData && 'Сохранить изменения'}
                  </Button>
                </Stack>
              </form>
            </CardContent>
          </BlankCard>
        </Grid>
      </Grid>
    </>

  );
};

export default AccountTab;

import { Box, Button, Card, CardContent, CardHeader, FormControl, FormLabel, Grid, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useUpdUserDataMutation } from "src/redux/api/cabinet";
import { updProfileData } from "src/redux/slices/app";
import { AppState } from "src/store/Store";
import { Alerting } from "src/views/components_cabinet/Alerting";

export const WalletsTab = () => {
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);
    const { data } = useSelector((state: AppState) => state.app);
    const [payeerWallet, setPayeerWallet] = useState<string>('');
    const dispatch = useDispatch();


    useEffect(() => {
        console.log("data");
        console.log(data);
        if (data.wallet) setPayeerWallet(data.wallet);
    }, [data])

    const SubmitForm = () => {
        const d = data;

        if (d.wallet) {
            setgetAlert({ type: 2, msg: 'Payeer кошелёк уже сохранён, изменение возможно только через Админа' });
            return false
        }

        if (!payeerWallet && payeerWallet.length < 2) {
            setgetAlert({ type: 2, msg: 'Укажите ваш Payeer кошелёк' });
            return false
        }

        const param = { wallet: payeerWallet };
        getRequest(param);
    }

    const [getData, { isLoading }] = useUpdUserDataMutation();
    const getRequest = async (data: any) => {
        const jwt = localStorage.getItem('jwt');
        await getData({ body: data, jwt })
            .unwrap()
            .then((payload: any) => {
                // console.log('getPartnersData');
                // console.log(payload);
                dispatch(updProfileData(payload.data));
                setgetAlert({ type: 1, msg: payload.message });
            })
            .catch((error: any) => {
                // console.log('err getPartnersData');
                // console.log(error);
                setgetAlert({ type: 2, msg: error.data.message });
            })
    }

    return (
        <>
            <Card sx={{ background: 'transparent', boxShadow: 'none', borderColor: 'transparent' }}>
                <CardHeader title="Вывести" />
                <CardContent>
                    <Grid container display='flex' justifyContent='center'>
                        <Grid item xs={12} md={8} display='flex' justifyContent='center'>
                            <Box width='100%' display='flex' flexDirection='column'>
                                <FormControl sx={{ width: '100%', mb: 1 }}>
                                    <FormLabel htmlFor='amount'>
                                        Кошелёк Payeer
                                    </FormLabel>
                                    <TextField value={payeerWallet} size='medium' id="amount" variant="outlined" disabled={data.wallet ? true : false}
                                        onInput={(el: ChangeEvent<HTMLInputElement>) => setPayeerWallet(el.target.value.toUpperCase())}
                                        placeholder="Введите кошелёк"
                                    />
                                </FormControl>
                                {!data.wallet && <Button onClick={() => SubmitForm()} size="small" sx={{ maxWidth: { xs: '100%', md: "160px" } }} variant="contained" disabled={isLoading}>Сохранить</Button>}
                                {data.wallet && <Button size="small" sx={{ maxWidth: { xs: '100%', md: "160px" } }} variant="contained" disabled>Сохранить</Button>}
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {getAlert && <Alerting get={getAlert} />}
        </>
    )
}

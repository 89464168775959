import { Box, Button, CardContent, TextField, Typography } from "@mui/material"
import CustomFormLabel from "src/components/forms/theme-elements/CustomFormLabel"
import BlankCard from "src/components/shared/BlankCard"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import { useUpdUserDataMutation } from "src/redux/api/cabinet";

export const UpdatePasswordBlock = () => {
    const notifyErr = (msg: string) => toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // theme: "colored",
    });
    const notify = (msg: string) => toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // theme: "colored",
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data: any) => {
        console.log('Отправка данных ');
        console.log(data);
        // setRequestErr('')
        userUpdatePasswoedRequest(data);
    }

    const [userUpdatePasswordData, { isLoading }] = useUpdUserDataMutation();
    const userUpdatePasswoedRequest = async (body: any) => {
        const jwt = localStorage.getItem('jwt');
        await userUpdatePasswordData({ body, jwt })
            .unwrap()
            .then((payload: any) => {
                console.log('userUpdatePasswordData');
                console.log(payload);
                // dispatch(updProfileData(payload.data));
                notify(payload.message);
                // setAlert(true);
                // setAlertMsg('Данные успешно изменены')
                // setAlertStatus('success')
            })
            .catch((error: any) => {
                console.log('err userUpdatePasswordData');
                console.log(error);
                notifyErr(error.data.message);
                // setAlert(true);
                // setAlertMsg(error.data.message)
                // setAlertStatus('success')
            })
    }

    return (
        <>
            <ToastContainer />
            <BlankCard>
                <CardContent>
                    <Typography variant="h5" mb={1}>
                        Изменить пароль
                    </Typography>
                    {/* <Typography color="textSecondary" mb={3}>Изменить текущий пароль можно здесь</Typography> */}
                    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                        <CustomFormLabel htmlFor="text-npwd">Новый пароль</CustomFormLabel>
                        <TextField
                            placeholder="********"
                            variant="outlined"
                            fullWidth
                            type="password"
                            error={errors.password ? true : false}
                            helperText={errors.password ? `${errors.password.message}` : ``}
                            {...register("password", {
                                required: { value: true, message: "Поле пароль обязательно для заполнения" },
                                minLength: { value: 8, message: "Минимальная длинна 8 символов" }
                            })}
                        />
                        <CustomFormLabel htmlFor="text-conpwd">Повторите новый пароль</CustomFormLabel>
                        <TextField
                            placeholder="********"
                            variant="outlined"
                            fullWidth
                            type="password"
                            error={errors.password_confirm ? true : false}
                            helperText={errors.password_confirm ? `${errors.password_confirm.message}` : ``}
                            {...register("password_confirm", {
                                required: { value: true, message: "Поле повторения пароля обязательно для заполнения" },
                                minLength: { value: 8, message: "Минимальная длинна 8 символов" }
                            })}
                        />
                        <CustomFormLabel htmlFor="text-conpwd">Подтвердите изменение фин.паролем</CustomFormLabel>
                        <TextField
                            placeholder="********"
                            variant="outlined"
                            fullWidth
                            type="password"
                            error={errors.finance_password ? true : false}
                            helperText={errors.finance_password ? `${errors.finance_password.message}` : ``}
                            {...register("finance_password", {
                                required: { value: true, message: "Поле ввода фин. пароля обязательно для заполнения" },
                                minLength: { value: 8, message: "Минимальная длинна 8 символов" }
                            })}
                        />
                        <Box mt={2}>
                            <Button disabled={isLoading} type="submit" color="primary" size="large" variant="contained">
                                {isLoading && 'Загрузка...'}
                                {!isLoading && "Изменить пароль"}
                            </Button>
                        </Box>
                    </form>
                </CardContent>
            </BlankCard>
        </>
    )
}

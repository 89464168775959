import { Avatar, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Skeleton, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react"
import { useUserInfoMutation } from "src/redux/api/cabinet";
import CloseIcon from '@mui/icons-material/Close';

import x10level from "../../../../assets/images/x10level.png";
import tg from "../../../../assets/images/socials/tg.png";
import vk from "../../../../assets/images/socials/vk.png";
import wa from "../../../../assets/images/socials/wa.png";

interface IUserInfoModal {
    data: any
    stateModal: boolean
    updateModalState: () => void
}

export const UserInfoModal: FC<IUserInfoModal> = ({ data, stateModal, updateModalState }) => {
    const [modalData, setModalData] = useState<any>(undefined);
    const [errText, setErrText] = useState<any>('');
    const [clone, setClone] = useState<number | undefined>(undefined);

    const closeModal = () => {
        updateModalState();
    }


    const [infoUsrData, { isLoading, isError, isSuccess }] = useUserInfoMutation();
    const infoUsrRequest = async (user_id: number) => {
        const jwt = localStorage.getItem('jwt');
        setModalData(undefined);
        await infoUsrData({ user_id, jwt })
            .unwrap()
            .then((payload: any) => {
                // setPositionsArr(payload.data);

                // console.log('infoUsrData');
                // console.log(payload);
                // dispatch(updProfileData(payload.data));
                // setgetAlert({ type: 1, msg: payload.message });

                setModalData(payload.data);
            })
            .catch((error: any) => {
                // console.log('err infoUsrData');
                // console.log(error);
                setErrText(error.data.message);
                // setgetAlert({ type: 2, msg: error.data.message });
                // setNeedPaymantBtn(!error.paymant);
            })
    }

    useEffect(() => {
        console.log(data);
        if (data) {
            setClone(data.clones)
            infoUsrRequest(data.user_id)
        };
    }, [data])


    return (
        <>
            <Dialog
                open={stateModal}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                {!modalData && isError &&
                    <>
                        <DialogTitle id="alert-dialog-title" display='flex' justifyContent='end'>
                            <IconButton sx={{ ml: 'auto' }} onClick={updateModalState} autoFocus>
                                <CloseIcon sx={{ fontWeight: 600, fontSize: '2rem', color: '#000' }} />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography variant="h4" color='error'>Ошибка при загрузке данных пользователя</Typography>
                                {errText}
                            </DialogContentText>
                        </DialogContent>
                    </>
                }

                {modalData && isSuccess &&
                    <>
                        <DialogTitle id="alert-dialog-title" display='flex' justifyContent='end'>
                            <IconButton sx={{ ml: 'auto' }} onClick={updateModalState} autoFocus>
                                <CloseIcon sx={{ fontWeight: 600, fontSize: '2rem', color: '#000' }} />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Box display='flex' justifyContent='center'>
                                    <Avatar
                                        sx={{ width: '80px', height: '80px' }}
                                        src={modalData.avatar ? modalData.avatar : x10level}
                                    />
                                </Box>
                                <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' mt={3}>
                                    <Typography variant="h5" sx={{ color: '#7B7373', fontStyle: 'italic', fontWeight: 500 }}>{modalData.login}</Typography>
                                    <Typography variant="h3" sx={{ color: '#000', fontWeight: 500 }}>{modalData.first_name} {modalData.last_name}</Typography>
                                </Box>

                                <Box display='flex' justifyContent='center' alignItems='center' mt={3}>
                                    <Chip color="success" sx={{ color: '#000', fontSize: '20px' }} label={clone && clone > 0 ? `Клон ${clone}` : 'Корневой аккаунт'} />
                                </Box>

                                <Box my={3}>
                                    <Typography variant="h4" sx={{ color: '#7B7373', fontWeight: 500, mb: 1 }}>Был в сети: {!modalData.last_online ? 'Еще не заходил' : modalData.last_online}</Typography>
                                    <Typography variant="h4" sx={{ color: '#7B7373', fontWeight: 500 }}>Куратор: {modalData.referer_name} ({modalData.referer_login})</Typography>
                                </Box>

                                <Typography textAlign='center' variant="h6" component='div' sx={{ color: '#7B7373', fontWeight: 400, mb: 1 }}>{modalData.email}</Typography>

                                <Box display='flex' justifyContent='center' alignItems='center' mt={1}>

                                    {modalData.vk && <a href={modalData.vk} target="_blank" rel="noreferrer">
                                        <img
                                            style={{ width: '38px', height: '38px', objectFit: 'contain' }}
                                            src={vk}
                                            alt=''
                                        />
                                    </a>}

                                    {modalData.tg && <a href={modalData.tg} target="_blank" rel="noreferrer">
                                        <img
                                            style={{ width: '38px', height: '38px', objectFit: 'contain' }}
                                            src={vk}
                                            alt=''
                                        />
                                    </a>}

                                    {modalData.wa && <a href={modalData.wa} target="_blank" rel="noreferrer">
                                        <img
                                            style={{ width: '38px', height: '38px', objectFit: 'contain' }}
                                            src={vk}
                                            alt=''
                                        />
                                    </a>}

                                </Box>
                            </DialogContentText>
                        </DialogContent>

                    </>
                }

                {!modalData && isLoading &&
                    <>
                        <DialogTitle id="alert-dialog-title" display='flex' justifyContent='end'>
                            <IconButton sx={{ ml: 'auto' }} onClick={updateModalState} autoFocus>
                                <CloseIcon sx={{ fontWeight: 600, fontSize: '2rem', color: '#000' }} />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Box height='300px' width='300px' display='flex' justifyContent='center' alignItems='center'>
                                    <CircularProgress />
                                </Box>
                            </DialogContentText>
                        </DialogContent>
                    </>
                }

            </Dialog>
        </>
    )
}

import PageContainer from "src/components/container/PageContainer"
import { Form } from "./Form";
import { Box } from "@mui/material";
import { FormPayeer } from "./FormPayeer";

const { REACT_APP_NAME } = process.env; // глобальная переменная

export const TopUpBalance = () => {
    return (
        <>
            <PageContainer title={`${REACT_APP_NAME} | Пополнить баланс`} description="Пополнить баланс аккаунта">
                <Box sx={{ my: 5 }}>
                    {/* <Form /> */}
                    <FormPayeer />
                </Box>
            </PageContainer>
        </>
    )
}



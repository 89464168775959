const { REACT_APP_NAME } = process.env;

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Card, CardContent, CardHeader, Divider, Grid, Tab, Typography } from "@mui/material"
import PageContainer from "src/components/container/PageContainer"
import React, { useEffect, useState } from "react";
import { useGetPromoMutation } from "src/redux/api/cabinet";
import { ContentBlock } from "./ContentBlock";
import { SkeletLoadBlock } from "./SkeletLoadBlock";
import { Alerting } from "src/views/components_cabinet/Alerting";


// interface TabType {
//     value: string;
//     label: string;
//     disabled?: boolean;
// }

// const COMMON_TAB: TabType[] = [
//     { value: '1', label: 'Item One', disabled: false },
//     { value: '2', label: 'Item Two', disabled: false },
//     { value: '3', label: 'Item Three', disabled: true }
// ];


export const PromoOfficePage = () => {
    const [value, setValue] = React.useState('1');
    const [COMMON_TAB, setCOMMON_TAB] = React.useState<any>([]);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);
    const [errtext, setErrtext] = useState<string>('');


    const [requestData, { isLoading, isError }] = useGetPromoMutation();
    const Request = async () => {
        const jwt = localStorage.getItem('jwt');
        await requestData({ jwt })
            .unwrap()
            .then((payload: any) => {
                setCOMMON_TAB(payload.data);
                // setPositionsArr(payload.data);
                // setLevels(payload.levels);
                // console.log('requestData');
                // console.log(payload);
                // dispatch(updProfileData(payload.data));
                // setgetAlert({ type: 1, msg: payload.message });
            })
            .catch((error: any) => {
                // console.log('err requestData');
                // console.log(error);
                // setLevels(error.data.levels);
                setErrtext(error.data.message);
                setgetAlert({ type: 2, msg: error.data.message });
                // setNeedPaymantBtn(!error.paymant);
            })
    }

    useEffect(() => {
        Request();
    }, [])


    // useEffect(() => {
    //     console.log('COMMON_TAB');
    //     console.log(COMMON_TAB);

    // }, [COMMON_TAB])

    return (
        <>
            {/* всплывашка */}
            {getAlert && <Alerting get={getAlert} />}

            {!isLoading ?
                <PageContainer title={`${REACT_APP_NAME} | Промо материалы`} description="Главная страница кабинета">
                    <Box mt={3}>
                        <Card>
                            <CardHeader
                                title="Наши промо материалы"
                                subheader="Тут будут загружаться все промо клуба"
                            />

                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} display="flex">
                                        <Box width='100%'>
                                            <TabContext value={value}>
                                                <Box>
                                                    <TabList variant="scrollable"
                                                        scrollButtons="auto" onChange={handleChange} aria-label="Таб контента">
                                                        {COMMON_TAB && COMMON_TAB.map((tab: any, index: number) => (
                                                            <Tab key={index} label={tab.title} value={String(index + 1)} />
                                                        ))}
                                                    </TabList>
                                                </Box>
                                                {/* <Divider /> */}
                                                <Box bgcolor="grey.200" mt={2}>
                                                    {!isError && COMMON_TAB && COMMON_TAB.map((panel: any, index: number) => (
                                                        <TabPanel key={index} sx={{ px: 1 }} value={String(index + 1)}>
                                                            {panel.protected ?
                                                                <ContentBlock key={index} data={panel.media} />
                                                                :
                                                                <Box py={5}>
                                                                    <Typography variant="h4" textAlign='center' color='error'>
                                                                        Данный раздел доступен только для активных участников клуба
                                                                    </Typography>
                                                                </Box>
                                                            }
                                                        </TabPanel>
                                                    ))}

                                                    {isError &&
                                                        <TabPanel value={String(1)}>
                                                            <Box py={5}>
                                                                <Typography variant="h4" textAlign='center' color='error'>
                                                                    Произошла ошибка вовремя загрузки данных
                                                                </Typography>
                                                                <Typography variant="h6" textAlign='center'>
                                                                    {errtext ? `Текст ошибки: ${errtext}` : 'Неизвестная ошибка'}
                                                                </Typography>
                                                            </Box>
                                                        </TabPanel>
                                                    }
                                                </Box>
                                            </TabContext>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                </PageContainer>
                :
                <SkeletLoadBlock />
            }


        </>
    )
}

import { createSlice } from '@reduxjs/toolkit';

export interface IinitialState {
  issetCard: boolean;
  load: boolean;
  auth: boolean;
  data: any;
  structure_data: any;
  structure_history: any;
  activeTimer: boolean;
}

// const { VITE_FRONT_URL } = import.meta.env;

export const initialState: IinitialState = {
  issetCard: false, // существует карта
  load: true, // в загрузке
  auth: false, // авторизован
  data: undefined, // данные пользователя
  structure_data: undefined,
  structure_history: undefined,
  activeTimer: true,
};

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    upd_structure_history(state, { payload }) {
      state.structure_history = payload;
    },
    updLoad(state, { payload }) {
      state.load = payload.state;
    },
    updAuth(state, { payload }) {
      state.auth = payload.auth;
    },
    iLogout(state) {
      localStorage.removeItem('jwt');
      state.data = undefined;
      state.auth = false;
      state.load = false;
      state.issetCard = false;
    },
    iLogin(state, { payload }) {
      state.data = payload;
      state.auth = true;
    },
    updProfileData(state, { payload }) {
      state.data = payload;
    },

    updActiveTimer(state, { payload }) {
      state.activeTimer = payload.state; // передаем state:true/false
    },
    updBalances(state, { payload }) {
      state.data.balance = payload.balance;
      state.data.balanceYan = payload.balanceYan;
      state.data.balance_bonus = payload.balance_bonus;
    },
  },
  // extraReducers: () => {},
});

export const {
  updAuth,
  updLoad,
  iLogout,
  iLogin,
  updBalances,
  updProfileData,
  upd_structure_history,
  updActiveTimer,
} = app.actions;
export const appReducer = app.reducer;

import { FC, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';

interface IAlert {
    get: any;
}
export const Alert: FC<IAlert> = ({ get }) => {
    const notifyErr = (msg: string) => toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });

    const notify = (msg: string) => toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });

    useEffect(() => {
        if (get.type === 1) notify(get.msg);
        else if (get.type === 2) notifyErr(get.msg);
    }, [get])


    return <ToastContainer />
}

import { Avatar, Box, Button, IconButton, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux';
import user1 from 'src/assets/images/profile/user-1.jpg';
import { AppState } from 'src/store/Store';
import EditIcon from '@mui/icons-material/Edit';
import { useForm } from 'react-hook-form';
import { useUpdUserDataMutation } from 'src/redux/api/cabinet';
import { useEffect, useState } from 'react';
import { updProfileData } from 'src/redux/slices/app';
import { useDispatch } from 'react-redux';
import { Alert } from './Alert';


export const UpdateAvatar = () => {
    const { data } = useSelector((state: AppState) => state.app);
    const [inputDisabled, setInputDisabled] = useState<boolean>(true);
    const [tempImage, setTempImage] = useState<any>(undefined);
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);

    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data: any) => {
        // console.log('Отправка данных ');
        // console.log(data);

        if (data[0].size > 2048000) {
            setgetAlert({ type: 2, msg: 'Файл превышает допустимый размер 2мб' });
            return false;
        }

        const formData: FormData = new FormData();
        formData.append(`avatar`, data[0])
        userUpdateRequest(formData);
    }

    const updStateNewImage = (file: any) => {
        setInputDisabled(false);
        setTempImage(file);
    }
    const succUpdate = () => {
        setInputDisabled(true);
        setgetAlert({ type: 1, msg: 'Изменения сохранены' });

    }

    const [userUpdateData, { isLoading: loadUpdateData }] = useUpdUserDataMutation();
    const userUpdateRequest = async (body: any) => {
        const jwt = localStorage.getItem('jwt');
        await userUpdateData({ body, jwt })
            .unwrap()
            .then((payload: any) => {
                // console.log('userUpdateData');
                // console.log(payload);
                dispatch(updProfileData(payload.data));
                setTempImage(undefined);
                succUpdate();
            })
            .catch((error: any) => {
                // console.log('err userUpdateData');
                // console.log(error);
                setgetAlert({ type: 2, msg: error.data.message });
            })
    }

    return (
        <>
            <Box>
                <form onSubmit={handleSubmit((data) => onSubmit(tempImage))}>
                    <Box position='relative' display='inline'>
                        <Avatar
                            src={data ? data.avatar : user1}
                            alt={data && `${data.first_name} ${data.last_name}`}
                            sx={{ width: 120, height: 120, margin: '0 auto' }}
                        />

                        <IconButton component="label" color='primary' sx={{ background: "#061dea2e", position: 'absolute', bottom: 0, right: '50%' }}>
                            <EditIcon />
                            <input
                                {...register("avatar", {
                                    required: { value: true, message: "Выберите аватарку для изменения" },
                                })}
                                onChange={(el) => {
                                    updStateNewImage(el.target.files);
                                }
                                }
                                hidden accept="image/*" type="file" />
                        </IconButton>
                    </Box>

                    <Stack direction="row" justifyContent="center" spacing={2} my={4}>
                        <Button variant="contained" color="primary" type='submit'
                            disabled={inputDisabled}
                        >
                            {loadUpdateData && "Идет загрузка..."}
                            {!loadUpdateData && "Загрузить"}
                        </Button>
                        {/* <Button variant="outlined" color="error">
                    Удалить текущую
                    </Button> */}
                    </Stack>
                </form>
                <Typography variant="subtitle1" color="textSecondary" mb={4}>
                    Формат JPG, GIF или PNG. Максимальный вес 2мб
                </Typography>
            </Box >
            {getAlert && <Alert get={getAlert} />}
        </>
    )
}

import { Typography } from '@mui/material'
import { FC } from 'react'
import { Link } from 'react-router-dom'

interface IChainItem {
    item: any
    active: boolean
}

export const ChainItem: FC<IChainItem> = ({ item, active }) => {

    // level : 600
    // mentor_id : 7590
    // mentor_name : "Admin1(Клон 1)"
    // program : 1

    return (
        <>
            <Typography
                component={Link}
                to={`?p=${item.program}&l=${item.level}&m=${item.mentor_id}`}
                variant='h6' sx={{ "&:hover": { color: "#041ee9" }, cursor: "pointer", color: active ? "red" : "#000", mr: 1 }}>
                {item.mentor_name}
            </Typography>
        </>
    )
}

import { Avatar, Box, Button, Skeleton, Typography } from "@mui/material"
import { LoginButton } from '@telegram-auth/react';
import { useEffect, useState } from "react";
import { Alerting } from "src/views/components_cabinet/Alerting";
import { useTelegramAuthMutation, useGetTelegramMutation, useTelegramOutMutation } from "src/redux/api/cabinet";

export const SCR = () => {

    const [tgData, setTgData] = useState<any>(undefined);
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);

    const [evntData] = useTelegramAuthMutation();
    const evntRequest = async (body: any) => {
        const jwt = localStorage.getItem('jwt');
        await evntData({ body, jwt })
            .unwrap()
            .then((payload: any) => {
                // console.log('evntData');
                // console.log(payload);
                setTgData(payload.data);
                setgetAlert({ type: 1, msg: payload.message });
            })
            .catch((error: any) => {
                // console.log('err evntData');
                // console.log(error);

                // setgetAlert({ type: 2, msg: error.data.message });
            })
    }


    const [evntData2, { isLoading: loadGet }] = useGetTelegramMutation();
    const evntRequest2 = async () => {
        const jwt = localStorage.getItem('jwt');
        await evntData2({ jwt })
            .unwrap()
            .then((payload: any) => {
                // console.log('evntData');
                // console.log(payload);
                setTgData(payload.data);
                // setgetAlert({ type: 1, msg: payload.message });
            })
            .catch((error: any) => {
                // console.log('err evntData');
                // console.log(error);

                // setgetAlert({ type: 2, msg: error.data.message });
            })
    }

    const [evntDataOut, { isLoading }] = useTelegramOutMutation();
    const evntRequestOut = async () => {
        const jwt = localStorage.getItem('jwt');
        await evntDataOut({ jwt })
            .unwrap()
            .then((payload: any) => {
                // console.log('evntData');
                // console.log(payload);
                setTgData(payload.data);
                setgetAlert({ type: 1, msg: payload.message });
            })
            .catch((error: any) => {
                // console.log('err evntData');
                // console.log(error);

                setgetAlert({ type: 2, msg: error.data.message });
            })
    }

    const removeTgAccount = () => {
        evntRequestOut()

    }

    useEffect(() => {
        evntRequest2()
    }, []);

    return <>
        {getAlert && <Alerting get={getAlert} />}

        {loadGet && <>
            <Box display="flex" justifyContent='center' flexDirection='column' alignItems='center'>
                <Skeleton variant="circular" width={80} height={80} sx={{ mb: 3 }} />
                <Typography variant="h6" textAlign='center' my={1}>
                    <Skeleton variant="rounded" width={230} />
                </Typography>
                <Typography variant="h6" textAlign='center' my={1}>
                    <Skeleton variant="rounded" width={140} height={40} />
                </Typography>
            </Box>
        </>}

        {!loadGet && tgData && <Box display="flex" justifyContent='center' flexDirection='column' alignItems='center' py={5}>
            <Box mb={2}>
                {tgData.telegram_avatar ?
                    <Avatar
                        sx={{ width: 80, height: 80 }}
                        src={tgData.telegram_avatar}
                    />
                    : <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="80"
                        fill="none"
                        viewBox="0 0 29 28"
                    >
                        <path
                            fill="#24BBDC"
                            d="M14.318 28c7.91 0 14.318-6.266 14.318-14S22.228 0 14.318 0C6.408 0 0 6.266 0 14s6.409 14 14.318 14zM6.552 13.697l13.805-5.205c.64-.226 1.2.153.993 1.1L19 20.418c-.174.768-.64.954-1.293.593l-3.58-2.58-1.726 1.627c-.191.186-.352.344-.722.344l.254-3.562 6.634-5.86c.289-.249-.064-.389-.445-.142l-8.198 5.047-3.535-1.077c-.767-.239-.783-.75.163-1.112z"
                        ></path>
                    </svg>

                }
            </Box>
            <Box mt={2}>
                <Typography variant="h6" textAlign='center' my={1}>
                    Подключен телеграм аккаунт <b>{tgData.telegram_login}</b>
                </Typography>
                <Box display='flex' justifyContent='center'>
                    <Button
                        disabled={isLoading}
                        onClick={removeTgAccount}
                        variant="outlined">
                        Отвязать телеграм
                    </Button>
                </Box>
            </Box>
        </Box>}

        {!loadGet && !tgData && <Box display="flex" justifyContent='center' flexDirection='column' alignItems='center' py={5}>
            <Box mb={2}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    fill="none"
                    viewBox="0 0 29 28"
                >
                    <path
                        fill="#24BBDC"
                        d="M14.318 28c7.91 0 14.318-6.266 14.318-14S22.228 0 14.318 0C6.408 0 0 6.266 0 14s6.409 14 14.318 14zM6.552 13.697l13.805-5.205c.64-.226 1.2.153.993 1.1L19 20.418c-.174.768-.64.954-1.293.593l-3.58-2.58-1.726 1.627c-.191.186-.352.344-.722.344l.254-3.562 6.634-5.86c.289-.249-.064-.389-.445-.142l-8.198 5.047-3.535-1.077c-.767-.239-.783-.75.163-1.112z"
                    ></path>
                </svg>
            </Box>

            <LoginButton
                botUsername={'EasyX10_bot'}
                onAuthCallback={(data) => {
                    // console.log(data);
                    evntRequest(data);
                }}
                buttonSize="large" // "large" | "medium" | "small"
                cornerRadius={20} // 0 - 20
                showAvatar={true} // true | false
                lang="ru"
            />
            <Typography variant="h6" textAlign='center' color='#A3A3A3' mt={2}>Привязать Telegram бота</Typography>
        </Box>}

    </>



}

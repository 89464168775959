import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Button, FormControl, FormLabel, IconButton, InputAdornment, OutlinedInput, TextField } from '@mui/material'
import React, { ChangeEvent, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useFinanceTransferBetweenMyBalancesMutation } from 'src/redux/api/cabinet';
import { updProfileData } from 'src/redux/slices/app';
import { Alerting } from 'src/views/components_cabinet/Alerting';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';


export const FormTransferBetweenMyBalances = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [login, setLogin] = React.useState<string>('');
    const [amount, setAmount] = React.useState<number>(0);
    const [FP, setFP] = React.useState<string>('');
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);
    const dispatch = useDispatch();

    const [balanceSend, setBalanceSend] = React.useState<string>('0');
    const [balanceRecive, setBalanceRecive] = React.useState<string>('0');

    const handleChange = (event: SelectChangeEvent) => {
        setBalanceSend(event.target.value as string);
    };

    const handleChange2 = (event: SelectChangeEvent) => {
        setBalanceRecive(event.target.value as string);
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const SubmitForm = () => {
        // if (login.length === 0) {
        //     setgetAlert({ type: 2, msg: 'Укажите логин получателя' });
        //     return false
        // }

        // if (amount === 0) {
        //     setgetAlert({ type: 2, msg: 'Укажите сумму перевода' });
        //     return false
        // }
        if (balanceSend === "0") {
            setgetAlert({ type: 2, msg: 'Выберите баланс отправитель' });
            return false
        }

        if (balanceRecive === "0") {
            setgetAlert({ type: 2, msg: 'Выберите баланс получатель' });
            return false
        }

        if (FP.length < 8) {
            setgetAlert({ type: 2, msg: 'Укажите Финансовый пароль (от 8 символов)' });
            return false
        }

        const data = { finance_password: FP, balance_send: balanceSend, balance_receive: balanceRecive, amount: amount };
        // console.log(data);

        getRequest(data);
    }

    const [getData, { isLoading }] = useFinanceTransferBetweenMyBalancesMutation();
    const getRequest = async (data: any) => {
        const jwt = localStorage.getItem('jwt');
        await getData({ body: data, jwt })
            .unwrap()
            .then((payload: any) => {
                // console.log('getPartnersData');
                // console.log(payload);
                dispatch(updProfileData(payload.data));
                setgetAlert({ type: 1, msg: payload.message });
                setLogin('');
                setAmount(0);
                setFP('');
            })
            .catch((error: any) => {
                setgetAlert({ type: 2, msg: error.data.message });
            })
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                {/* <FormControl sx={{ width: '100%', mb: 4 }}>
                    <FormLabel htmlFor='login'>
                        Логин получателя
                    </FormLabel>
                    <TextField value={login} size='medium' id="login" variant="outlined"
                        onInput={(el: ChangeEvent<HTMLInputElement>) => setLogin(el.target.value)}
                        placeholder="Логин"
                    />
                </FormControl> */}

                <FormControl fullWidth sx={{ mb: 3, "& .MuiInputBase-root": { background: "#EDEDED" }, }}>
                    {/* <InputLabel id="demo-simple-select-label">Баланс отправитель</InputLabel> */}
                    <FormLabel htmlFor='blssender'>
                        Баланс отправитель
                    </FormLabel>
                    <Select
                        // labelId="demo-simple-select-label"
                        id="blssender"
                        value={balanceSend}
                        // label="Баланс отправитель"
                        onChange={handleChange}
                        sx={{
                            "& .MuiOutlinedInput-notchedOutline": { background: "none" }
                        }}
                    >
                        <MenuItem value="0">Выбрать</MenuItem>
                        <MenuItem value="balance">Основной баланс</MenuItem>
                        <MenuItem value="balance_zho">Баланс ЖО</MenuItem>
                        <MenuItem value="balance_null">Баланс NULL</MenuItem>
                    </Select>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3, "& .MuiInputBase-root": { background: "#EDEDED" }, }}>
                    {/* <InputLabel id="demo-simple-select-label">Баланс отправитель</InputLabel> */}
                    <FormLabel htmlFor='blsrecive'>
                        Баланс получатель
                    </FormLabel>
                    <Select
                        id="blsrecive"
                        value={balanceRecive}
                        onChange={handleChange2}
                        sx={{
                            "& .MuiOutlinedInput-notchedOutline": { background: "none" }
                        }}
                    >
                        <MenuItem value="0">Выбрать</MenuItem>
                        <MenuItem value="balance">Основной баланс</MenuItem>
                        <MenuItem value="balance_zho">Баланс ЖО</MenuItem>
                        <MenuItem value="balance_null">Баланс NULL</MenuItem>
                    </Select>
                </FormControl>


                <FormControl sx={{ width: '100%', mb: 3 }}>
                    <FormLabel htmlFor='amount'>
                        Сумма
                    </FormLabel>
                    <TextField value={amount === 0 ? '' : amount} inputMode="numeric" size='medium' id="amount" variant="outlined" type='text'
                        onInput={(el: ChangeEvent<HTMLInputElement>) => setAmount(Number(el.target.value))}
                        placeholder="Сумма"
                    />
                </FormControl>
                <FormControl sx={{ width: '100%', mb: 3, position: 'relative' }}>
                    <Box display='flex' justifyContent='space-between' alignItems='end' mb={1}>
                        <FormLabel htmlFor='finpas'>
                            Финансовый пароль
                        </FormLabel>
                    </Box>

                    <OutlinedInput
                        value={FP}
                        id="finpas"
                        type={showPassword ? 'text' : 'password'}
                        onInput={(el: ChangeEvent<HTMLInputElement>) => setFP(el.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff color='error' /> : <Visibility color='error' />}
                                </IconButton>
                            </InputAdornment>
                        }
                        placeholder="Финансовый пароль"
                    />

                </FormControl>
                <Button onClick={SubmitForm} color='primary' variant='contained' sx={{ width: '100%' }} disabled={isLoading}>
                    {isLoading ? 'Обработка' : 'Отправить'}
                </Button>
            </Box >
            {getAlert && <Alerting get={getAlert} />
            }
        </>
    )
}

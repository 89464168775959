const { REACT_APP_NAME } = process.env;

import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material"
import { FormTransferMoney } from "./FormTransferMoney"
import PageContainer from "src/components/container/PageContainer"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from "react";
import { FormTransferBetweenMyBalances } from "./FormTransferBetweenMyBalances";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, px: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const TransferMoneyOfficePage = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <PageContainer title={`${REACT_APP_NAME} | Переводы`} description="Перевод средств на другой аккаунт">
                <Box sx={{ my: 5 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Переводы другим" sx={{ textTransform: "none" }} {...a11yProps(0)} />
                            <Tab label="Переводы по своим счетам" sx={{ textTransform: "none" }} {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Card>
                            <CardHeader title="Переводы другим" />
                            <CardContent>
                                <Grid container display='flex' justifyContent='center'>
                                    <Grid item xs={12} md={8} display='flex' justifyContent='center'>
                                        <FormTransferMoney />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Card>
                            <CardHeader title="Переводы по своим счетам" />
                            <CardContent>
                                <Grid container display='flex' justifyContent='center'>
                                    <Grid item xs={12} md={8} display='flex' justifyContent='center'>
                                        <FormTransferBetweenMyBalances />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </CustomTabPanel>

                </Box>
            </PageContainer>


        </>
    )
}

import { Button } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { usePayMarketingMutation } from 'src/redux/api/cabinet';
import { iLogin } from 'src/redux/slices/app';
import { Alerting } from 'src/views/components_cabinet/Alerting';

interface IPaymantContainer {
    paymantData: { program: number, level: number, mentor_id: number }
    updErrText: (s: string) => void
    updateNeedPaymant: (status: boolean) => void
}

export const PaymantContainer: FC<IPaymantContainer> = ({ paymantData, updErrText, updateNeedPaymant }) => {
    const dispatch = useDispatch();
    const [viewPayBtn, setViewPayBtn] = useState<boolean>(true);
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);
    const [reqData, { isLoading, isError }] = usePayMarketingMutation();
    const Request = async (body: any) => {
        const jwt = localStorage.getItem('jwt');
        await reqData({ body, jwt })
            .unwrap()
            .then((payload: any) => {
                setViewPayBtn(false);
                updErrText(payload.message);
                updateNeedPaymant(false)

                if (payload.data) dispatch(iLogin(payload.data));
                // setPositionsArr(payload.data);
                // setLevels(payload.levels);
                // console.log('levelsData');
                // console.log(payload);
                // dispatch(updProfileData(payload.data));
                // setgetAlert({ type: 1, msg: payload.message });
            })
            .catch((error: any) => {
                // console.log('err levelsData');
                // console.log(error);
                // setLevels(error.data.levels);
                // setErrtext(error.data.message);
                setgetAlert({ type: 2, msg: error.data.message });
                // setNeedPaymantBtn(!error.paymant);
            })
    }
    return (
        <>

            {/* всплывашка */}
            {getAlert && <Alerting get={getAlert} />}

            {viewPayBtn &&
                <Button sx={{ fontSize: '30px' }} onClick={() => Request(paymantData)} variant="contained" disabled={isLoading}>
                    {isLoading ? "Обработка платежа" : "Оплатить"}
                </Button>
            }
        </>
    )
}

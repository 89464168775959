import { Box } from '@mui/material'
import { FC } from 'react'
import { ChainItem } from './ChainItem';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store/Store';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface IStructureChain {
    arrChain: any[]
}

export const StructureChain: FC<IStructureChain> = ({ arrChain }) => {
    const { data } = useSelector((state: AppState) => state.app);

    const [searchParams] = useSearchParams();
    const levelParam = searchParams.get('l');
    const programParam = searchParams.get('p');
    const mentorParam = searchParams.get('m');


    // level : 600
    // mentor_id : 7590
    // mentor_name : "Admin1(Клон 1)"
    // program : 1

    return (
        <>
            <Box
                py={2}
                sx={{ width: "100%" }}
                display='flex'
                flexWrap='wrap'
                alignItems='center'
            >
                <Box display='flex' alignItems='center' mb={1}>
                    <ChainItem active={false} item={{ level: levelParam, mentor_name: data.login, mentor_id: 0, program: programParam }} />
                </Box>

                {arrChain.map((e, i) => {
                    // console.log('mentorParam');
                    // console.log(Number(mentorParam));
                    // console.log('mentor_id');

                    // console.log(Number(e.mentor_id));

                    return <Box key={i} display='flex' alignItems='center' mb={1}>
                        <ArrowForwardIcon sx={{ mr: 1 }} />
                        <ChainItem active={Number(mentorParam) === Number(e.mentor_id) ? true : false} item={{ level: e.level, mentor_name: e.mentor_name, mentor_id: e.mentor_id, program: e.program }} />
                    </Box>
                }

                )}
            </Box>
        </>
    )
}

import { Box, Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material"
import dayjs from "dayjs"
// import relativeTime from "dayjs/plugin/relativeTime"
import { FC } from "react"
import { TablePartners } from "./TablePartners"
import { useSelector } from "react-redux"
import { AppState } from "src/store/Store"

interface IPartnersContent {
    data: { info: any, sponsors: any, teams: any, count: number, referer: any }
    updPersonInfoId: (id: number) => void
}

export const PartnersContent: FC<IPartnersContent> = ({ data, updPersonInfoId }) => {
    const { data: user } = useSelector((state: AppState) => state.app);
    const dateRegUser = dayjs(data.info.created_at).format('DD.MM.YYYY');

    return (
        <>
            <Box>
                <Card >
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Typography variant="h5">Структура</Typography>
                        {user.id != data.info.id && <Box>
                            <Button
                                size="large"
                                sx={{ mr: 1 }}
                                color="primary"
                                variant="contained"
                                onClick={() => updPersonInfoId(user.id)}
                            >В начало</Button>
                            <Button
                                size="large"
                                // sx={{ background: "#13DEB9", color: "white" }}
                                variant="contained"
                                color="success"
                                onClick={() => updPersonInfoId(data.info.referer_id)}
                            >Назад</Button>
                        </Box>}
                    </Box>
                    <CardContent>
                        <Grid container spacing={2} justifyContent='center' display='flex'>
                            <Grid item xs={6} display='flex' alignItems='center' flexDirection='column'>
                                <Typography variant="h5">{data.info.first_name} ({data.info.login})</Typography>
                                <Typography sx={{ color: '#9c9c9c' }}>Логин пользователя</Typography>
                            </Grid>
                            <Grid item xs={6} display='flex' alignItems='center' flexDirection='column'>
                                <Typography variant="h5">{data.referer.name} ({data.referer.login})</Typography>
                                <Typography sx={{ color: '#9c9c9c' }}>Куратор</Typography>
                            </Grid>
                            <Grid item xs={6} md={3} display='flex' alignItems='center' flexDirection='column'>
                                <Typography variant="h5">{data.count} чел.</Typography>
                                <Typography sx={{ color: '#9c9c9c' }}>Всего людей</Typography>
                            </Grid>
                            <Grid item xs={6} md={3} display='flex' alignItems='center' flexDirection='column'>
                                <Typography variant="h5">{data.info.email}</Typography>
                                <Typography sx={{ color: '#9c9c9c' }}>E-mail</Typography>
                            </Grid>
                            <Grid item xs={6} md={3} display='flex' alignItems='center' flexDirection='column'>
                                <Typography variant="h5">{dateRegUser}</Typography>
                                <Typography sx={{ color: '#9c9c9c' }}>Дата регистрации</Typography>
                            </Grid>
                            <Grid item xs={6} md={3} display='flex' alignItems='center' flexDirection='column'>
                                <Typography variant="h5">{data.info.phone}</Typography>
                                <Typography sx={{ color: '#9c9c9c' }}>Номер телефона</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>


            <Box py={3}>
                <TablePartners updPersonInfoId={updPersonInfoId} teams={data.teams} />
            </Box>
        </>
    )
}

import { Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTimer } from 'react-timer-hook';

interface IMyTimer {
    expiryTimestamp: any
}

export const TextTimer: FC<IMyTimer> = ({ expiryTimestamp }) => {
    const [expire, setExpire] = useState(false)
    const {
        // totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        // isRunning,
        // start,
        // pause,
        // resume,
        // restart,
    } = useTimer({
        expiryTimestamp, onExpire: () => setExpire(true)
    });

    return (

        <>
            {expire && <Typography variant="h2" sx={{ fontWeight: 500, fontSize: '20px', color: "#0119f9" }}>Автосписание произошло</Typography>}

            {!expire && <Typography variant="h2" sx={{ fontWeight: 500, fontSize: '18px', color: "#0119f9" }}>
                {days < 10 ? `0${days}` : days} <Typography component='span' sx={{ fontWeight: 500, fontSize: '18px', color: "#0119f9" }}>{days === 1 ? "День" : days > 1 && days < 5 ? "Дня" : "Дней"}</Typography>&nbsp;:&nbsp;
                {hours < 10 ? `0${hours}` : hours} <Typography component='span' sx={{ fontWeight: 500, fontSize: '18px', color: "#0119f9" }}>{hours === 1 || hours === 21 ? "Час" : hours > 1 && hours < 5 || hours > 21 && hours < 25 ? "Часа" : "Часов"}</Typography>&nbsp;:&nbsp;
                {minutes < 10 ? `0${minutes}` : minutes} <Typography component='span' sx={{ fontWeight: 500, fontSize: '18px', color: "#0119f9" }}>{minutes % 10 === 1 ? "Минута" : minutes % 10 > 1 && minutes % 10 < 5 ? "Минуты" : "Минут"}</Typography>&nbsp;:&nbsp;
                {seconds < 10 ? `0${seconds}` : seconds} <Typography component='span' sx={{ fontWeight: 500, fontSize: '18px', color: "#0119f9" }}>{seconds % 10 === 1 ? "Сек." : seconds % 10 > 1 && seconds % 10 < 5 ? "Сек." : "Сек."}</Typography>&nbsp;
            </Typography>}

        </>
    )
}

import { Box, Grid, Typography } from "@mui/material"

import tg from "src/assets/images/brands/tg.png";
// import vk from "src/assets/images/brands/vk.png";
// import wa from "src/assets/images/brands/wa.png";

export const SupportOfficePage = () => {
    return (
        <>
            <Grid container display='flex' justifyContent='center' mt={3}>
                <Grid item xs={12} md={6} display={'flex'} flexDirection='column' justifyContent='center' alignItems='center'>
                    <Typography mb={1.5} textAlign='center' variant='h2'>Мы в соцсетях</Typography>
                    <Box display="flex" alignItems='center' mb={5}>
                        {/* <Box mr={2} display='inline-flex' justifyContent='center' flexDirection='column' alignItems='center'>
                            <a href="https://vk.com/club226701463" target="_blank" rel="noreferrer">
                                <img alt="" src={vk} style={{ width: '100%', objectFit: 'contain' }} />
                            </a>
                            <Typography variant="h6">Техподдержка</Typography>
                        </Box> */}
                        <Box mr={2} display='inline-flex' justifyContent='center' flexDirection='column' alignItems='center'>
                            <a href="https://t.me/X10_Easy" target="_blank" rel="noreferrer">
                                <img alt="" src={tg} style={{ width: '100%', objectFit: 'contain' }} />
                            </a>
                            <Typography textAlign='center' variant="h6">Новостной канал</Typography>
                        </Box>
                        <Box mr={2} display='inline-flex' justifyContent='center' flexDirection='column' alignItems='center'>
                            <a href="https://t.me/+9jfBsN7aggw2MjJi" target="_blank" rel="noreferrer">
                                <img alt="" src={tg} style={{ width: '100%', objectFit: 'contain' }} />
                            </a>
                            <Typography textAlign='center' variant="h6">ТехПоддержка</Typography>
                        </Box>
                        <Box mr={2} display='inline-flex' justifyContent='center' flexDirection='column' alignItems='center'>
                            <a href="https://t.me/+yu2Bq-hDoWQ5ODli" target="_blank" rel="noreferrer">
                                <img alt="" src={tg} style={{ width: '100%', objectFit: 'contain' }} />
                            </a>
                            <Typography textAlign='center' variant="h6">Официальный чат</Typography>
                        </Box>

                        <Box mr={2} display='inline-flex' justifyContent='center' flexDirection='column' alignItems='center'>
                            <a href="https://t.me/+3A4bfr1fcb02MzEy" target="_blank" rel="noreferrer">
                                <img alt="" src={tg} style={{ width: '100%', objectFit: 'contain' }} />
                            </a>
                            <Typography textAlign='center' variant="h6">ИНФО-Чат</Typography>
                        </Box>

                    </Box>
                    <Typography mb={1.5} textAlign='center' variant='h2'>Наша почта</Typography>
                    <Typography mb={2} textAlign='center' variant='h5'>easyx10club@gmail.com</Typography>

                </Grid>
            </Grid>
        </>
    )
}

import { Typography } from "@mui/material"
import { FC } from "react"
import { useSearchParams } from "react-router-dom"

interface IReturnM {
    levels: any
}

export const ReturnM: FC<IReturnM> = ({ levels }) => {
    const [searchParams] = useSearchParams();
    const levelParam = searchParams.get('l');

    const returnNumberLevel = () => {
        // return levelParam
        for (let i = 0; i < levels.length; i++) {
            const e = levels[i];
            if (Number(e.summ) == Number(levelParam)) return e.title2;
        }
    }


    return (
        <>
            {returnNumberLevel()}
        </>
    )
}

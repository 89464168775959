import { Box, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import React, { ChangeEvent, FC, useState } from 'react'
import Paper from '@mui/material/Paper';
import dayjs from "dayjs";
import { Link } from 'react-router-dom';

interface ITableAnaliz {
    rows: any[]
}


export const TableAnaliz: FC<ITableAnaliz> = ({ rows }) => {


    // const [rows, setRows] = useState<any>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    return (
        <>
            <Box mt={5}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">№</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="h6">Партнёр</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="h6">До закрытия</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="h6">Банк клонов</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="h6">Дата активации</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="h6">Просмотр</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {rows.length > 0 && rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row: any, i: number) => {
                                    return (
                                        <TableRow hover key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell component="th" scope="row">
                                                <Typography>{i + 1}</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                    {row.login}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                    {row.closed_text}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography
                                                    color={row.podpiska_color === 'warning' ? "#ff9900" : row.podpiska_color}
                                                    sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                    {row.podpiska}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                    {row.date}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton
                                                    component='a'
                                                    href={row.link}
                                                    target='_blank'
                                                    rel='noreferer'
                                                    sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="40"
                                                        fill="none"
                                                        viewBox="0 0 8 8"
                                                    >
                                                        <g fill="#1700CA" clipPath="url(#clip0_2164_2)">
                                                            <path d="M7.175 3.477c-.657-.8-2.047-1.733-3.563-1.733-1.523 0-2.913.94-3.564 1.733a.212.212 0 000 .27C.72 4.565 2.113 5.48 3.612 5.48c1.55 0 2.935-.969 3.563-1.734a.212.212 0 000-.27zm-2.12.135c0 .795-.648 1.443-1.443 1.443a1.445 1.445 0 01-1.444-1.443 1.445 1.445 0 012.886 0zm-4.556 0c.24-.249.816-.785 1.625-1.13a1.863 1.863 0 000 2.258A5.038 5.038 0 01.5 3.612zM5.1 4.74a1.863 1.863 0 000-2.258c.615.26 1.187.673 1.625 1.129-.24.248-.816.784-1.625 1.129z"></path>
                                                            <path d="M2.89 3.612a.723.723 0 101.446-.002.723.723 0 00-1.447.002zm1.02 0a.298.298 0 11-.596-.001.298.298 0 01.595 0zM3.611.92a.212.212 0 00.212-.213V.212a.212.212 0 00-.425 0v.496c0 .117.095.213.213.213zm1.267.284a.212.212 0 00.29-.08L5.452.63a.212.212 0 10-.37-.21L4.8.914a.212.212 0 00.08.29zm-2.825-.08a.212.212 0 00.37-.21L2.14.419a.212.212 0 00-.37.21l.283.496zm1.558 5.177a.212.212 0 00-.213.213v.496a.212.212 0 00.425 0v-.496a.212.212 0 00-.212-.213zm-1.268-.284a.212.212 0 00-.29.08l-.282.495a.212.212 0 00.369.21l.282-.495a.212.212 0 00-.079-.29zm2.825.08a.212.212 0 00-.369.21l.283.496a.212.212 0 10.369-.21l-.283-.496z"></path>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_2164_2">
                                                                <path fill="#fff" d="M0 0H7.223V7.223H0z"></path>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }

                            {rows.length === 0 && <>
                                <TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                    <TableCell scope="row" colSpan={5}>
                                        <Typography textAlign='center' variant='h6'>В структуре нет людей</Typography>
                                    </TableCell>
                                </TableRow>
                            </>}

                        </TableBody>

                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage="Строк на странице"
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </>
    )
}

import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Card, CardContent, CardHeader, Divider, Grid, Skeleton, Tab } from "@mui/material"
import { ContentBlock } from "./ContentBlock"

export const SkeletLoadBlock = () => {
    return (
        <>
            <Card>
                <CardHeader
                    title={<Skeleton variant="rounded" height='40px' width='180px' />}
                />

                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} display="flex">
                            <Box width='100%'>
                                <Box>
                                    <Box aria-label="Таб контента">
                                        <Skeleton variant="rounded" />
                                    </Box>
                                </Box>
                                <Divider />
                                <Box mt={2}>
                                    <Box aria-label="Таб контента">
                                        <Skeleton variant="rounded" height="300px" />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid >
                    </Grid >
                </CardContent >
            </Card >
        </>
    )
}

import { Box, Grid } from '@mui/material'
import { FC, useEffect } from 'react'
import { TabImageComponent } from './TabImageComponent'
import { TabYouTubeComponent } from './TabYouTubeComponent'
import { EmptyMedia } from './EmptyMedia'

interface IContentBlock {
    data: any
}

export const ContentBlock: FC<IContentBlock> = ({ data }) => {
    // useEffect(() => {
    //     console.log(data);
    // }, [])

    return (
        <>
            <Grid container spacing={2} width="100%">

                {data && data.length > 0 && data.map((el: any, i: number) => {
                    switch (el.type) {
                        case 'image':
                            return <Grid item xs={12} md={6}>
                                <TabImageComponent key={i} data={el} />
                            </Grid>
                        case 'youtube':
                            return <Grid key={i} item xs={12} md={6}><TabYouTubeComponent key={i} data={el} /></Grid>
                    }
                })}


            </Grid>
            {data && data.length === 0 && <EmptyMedia />}

        </>
    )
}

import { Box, CardContent, Divider, Grid, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import AccountTab from 'src/components/pages/account-setting/AccountTab';
import BlankCard from 'src/components/shared/BlankCard';
import { IconArticle, IconBell, IconLock, IconUserCircle } from '@tabler/icons';
import { PageUnderDev } from '../page-under-dev/PageUnderDev';
import { WalletsTab } from 'src/components/pages/account-setting/WalletsTab';
import TelegramProfileTab from 'src/components/pages/account-setting/TelegramProfileTab';

// import { UpdatePasswordUser } from 'src/components/apps/userprofile/profile/UpdatePasswordUser';
const { REACT_APP_NAME } = process.env;

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

export const ProfileOfficePage = () => {
    const [value, setValue] = useState(0);
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <PageContainer title={`${REACT_APP_NAME} | Профиль`} description="Управление данными вашего профиля">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <BlankCard>
                        <Box sx={{ maxWidth: { xs: 320, sm: 480, lg: "100%" } }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="basic tabs example"
                            >
                                <Tab
                                    iconPosition="start"
                                    icon={<IconUserCircle size="22" />}
                                    label="Аккаунт"
                                    {...a11yProps(0)}
                                />

                                <Tab
                                    iconPosition="start"
                                    icon={<IconBell size="22" />}
                                    label="Платёжные данные"
                                    {...a11yProps(1)}
                                />
                                {/* <Tab
                                    iconPosition="start"
                                    icon={<IconArticle size="22" />}
                                    label="Безопасность"
                                    {...a11yProps(2)}
                                /> */}
                                <Tab
                                    iconPosition="start"
                                    icon={<IconLock size="22" />}
                                    label="Телеграм-бот"
                                    {...a11yProps(2)}
                                />
                                {/* <Tab
                                    iconPosition="start"
                                    icon={<IconLock size="22" />}
                                    label="Управление подписками"
                                    {...a11yProps(4)}
                                /> */}
                            </Tabs>
                        </Box>
                        <Divider />
                        <CardContent>
                            <TabPanel value={value} index={0}>
                                <AccountTab />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <WalletsTab />
                                {/* <PageUnderDev /> */}
                            </TabPanel>
                            {/* <TabPanel value={value} index={2}>
                                <PageUnderDev />
                            </TabPanel> */}
                            <TabPanel value={value} index={2}>
                                {/* телега бот */}
                                <TelegramProfileTab />
                                {/* <PageUnderDev /> */}
                            </TabPanel>
                            {/* <TabPanel value={value} index={4}>
                                <PageUnderDev />
                            </TabPanel> */}
                        </CardContent>
                    </BlankCard>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

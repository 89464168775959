import { Box, Grid, LinearProgress, Skeleton, Theme, useMediaQuery } from "@mui/material"

export const SkeletonComp = () => {
    const md = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <>
            <Box>
                <LinearProgress sx={{ mb: 1 }} />
                <Grid container mb={5}>
                    <Grid item xs={12}>
                        <Skeleton variant="rounded" height={80} />
                    </Grid>
                </Grid>

                <Grid container mb={2} spacing={2} display='flex' justifyContent='center'>
                    <Grid item xs={12} display='flex' justifyContent='center'>
                        <Skeleton variant="rounded" height={180} width='300px' />
                    </Grid>
                </Grid>

                <Grid container mb={2} spacing={2} display='flex' justifyContent='center'>
                    <Grid item xs={6} display='flex' justifyContent='center'>
                        <Skeleton variant="rounded" height={180} width='100%' />
                    </Grid>
                    <Grid item xs={6} display='flex' justifyContent='center'>
                        <Skeleton variant="rounded" height={180} width='100%' />
                    </Grid>
                </Grid>

                <Grid container mb={2} spacing={2} display='flex' justifyContent='center'>
                    <Grid item xs={3} display='flex' justifyContent='center'>
                        <Skeleton variant="rounded" height={180} width='100%' />
                    </Grid>
                    <Grid item xs={3} display='flex' justifyContent='center'>
                        <Skeleton variant="rounded" height={180} width='100%' />
                    </Grid>
                    <Grid item xs={3} display='flex' justifyContent='center'>
                        <Skeleton variant="rounded" height={180} width='100%' />
                    </Grid>
                    <Grid item xs={3} display='flex' justifyContent='center'>
                        <Skeleton variant="rounded" height={180} width='100%' />
                    </Grid>
                </Grid>


                {/*  {md &&
                        <Grid container spacing={2} display='flex' justifyContent='center'>
                            <Grid item xs={3} display='flex' justifyContent='center'>
                                <Skeleton variant="rounded" height={180} />
                            </Grid>
                            <Grid item xs={3} display='flex' justifyContent='center'>
                                <Skeleton variant="rounded" height={180} />
                            </Grid>
                            <Grid item xs={3} display='flex' justifyContent='center'>
                                <Skeleton variant="rounded" height={180} />
                            </Grid>
                            <Grid item xs={3} display='flex' justifyContent='center'>
                                <Skeleton variant="rounded" height={180} />
                            </Grid>
                        </Grid> */}
                {/* } */}
            </Box>
        </>
    )
}

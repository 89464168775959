import React, { ChangeEvent, FC, useEffect, useState } from "react"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, Box, Button, IconButton, TableFooter, Typography, useTheme } from "@mui/material";
import TablePagination from '@mui/material/TablePagination';
import dayjs from "dayjs";

import tg from "src/assets/images/socials/tg.png";
import vk from "src/assets/images/socials/vk.png";
import wa from "src/assets/images/socials/wa.png";

// const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

interface ITablePartners {
    teams: any[]
    updPersonInfoId: (id: number) => void
}

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}



export const TablePartners: FC<ITablePartners> = ({ teams, updPersonInfoId }) => {
    const [rows, setRows] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const updPersonInfoIdLocal = (id: number) => updPersonInfoId(id);

    useEffect(() => {
        // console.log('просмотр teams');
        setRows(teams.length);
    }, [teams])

    return (
        <>
            {rows > 0 &&
                <>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Имя Фамилия</TableCell>
                                    <TableCell align="center">Логин</TableCell>
                                    <TableCell align="center">Дата регистрации</TableCell>
                                    <TableCell align="center">Контакты</TableCell>
                                    <TableCell align="center">Баланс клонов</TableCell>
                                    <TableCell align="center">Баланс</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {teams.map((row: any, i: number) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row"
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => updPersonInfoIdLocal(row.id)}
                                        >
                                            <Box display='flex' alignItems='center'>
                                                <Typography variant="h6" sx={{ mr: 1.5 }}>{i + 1}</Typography>
                                                {row.avatar && <Avatar
                                                    alt={`${row.first_name} ${row.last_name}`}
                                                    src={row.avatar}
                                                />}

                                                {!row.avatar && <Avatar  {...stringAvatar(`${row.first_name} ${row.last_name}`)} />}
                                                <Box sx={{ ml: 1 }}>
                                                    <Typography variant="h6">
                                                        {row.first_name} {row.last_name}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="h6" sx={{  fontWeight: 600 }}>
                                                {row.login}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="h6" sx={{  fontWeight: 600 }}>
                                                {dayjs(row.created_at).format('DD.MM.YYYY')}
                                            </Typography>
                                            <Typography variant="h6" sx={{  fontWeight: 600 }}>
                                                {dayjs(row.created_at).format('HH:mm:ss')}
                                            </Typography>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Typography textAlign='center' variant="h6" sx={{  fontWeight: 600, marginBottom: '8px' }}>
                                                {row.email}
                                            </Typography>
                                            <Box display='flex' justifyContent='center'>
                                                {row.vk && <a href={vk} target="_blank" rel="noreferrer" style={{ marginRight: '10px' }}>
                                                    <Avatar src={vk} sx={{ width: 24, height: 24 }} />
                                                </a>}

                                                {row.telegram && <a href={tg} target="_blank" rel="noreferrer" style={{ marginRight: '10px' }}>
                                                    <Avatar src={tg} sx={{ width: 24, height: 24 }} />
                                                </a>}

                                                {row.whatsapp && <a href={wa} target="_blank" rel="noreferrer" style={{ marginRight: '10px' }}>
                                                    <Avatar src={wa} sx={{ width: 24, height: 24 }} />
                                                </a>}
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center">
                                            В разработке
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                                {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(row.balance)}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))} */}

                                {teams
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, i) => {
                                        return (
                                            <TableRow hover
                                                key={row.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <>
                                                    <TableCell component="th" scope="row"
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => updPersonInfoIdLocal(row.id)}
                                                    >
                                                        <Box display='flex' alignItems='center'>
                                                            <Typography variant="h6" sx={{ mr: 1.5 }}>{i + 1}</Typography>
                                                            {row.avatar && <Avatar
                                                                alt={`${row.first_name} ${row.last_name}`}
                                                                src={row.avatar}
                                                            />}

                                                            {!row.avatar && <Avatar  {...stringAvatar(`${row.first_name} ${row.last_name}`)} />}
                                                            <Box sx={{ ml: 1 }}>
                                                                <Typography variant="h6">
                                                                    {row.first_name} {row.last_name}
                                                                </Typography>
                                                            </Box>
                                                        </Box>

                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                                            {row.login}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                                            {dayjs(row.created_at).format('DD.MM.YYYY')}
                                                        </Typography>
                                                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                                            {dayjs(row.created_at).format('HH:mm:ss')}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Typography textAlign='center' variant="h6" sx={{ fontWeight: 600, marginBottom: '8px' }}>
                                                            {row.email}
                                                        </Typography>
                                                        <Box display='flex' justifyContent='center'>
                                                            {row.vk && <a href={vk} target="_blank" rel="noreferrer" style={{ marginRight: '10px' }}>
                                                                <Avatar src={vk} sx={{ width: 24, height: 24 }} />
                                                            </a>}

                                                            {row.telegram && <a href={tg} target="_blank" rel="noreferrer" style={{ marginRight: '10px' }}>
                                                                <Avatar src={tg} sx={{ width: 24, height: 24 }} />
                                                            </a>}

                                                            {row.whatsapp && <a href={wa} target="_blank" rel="noreferrer" style={{ marginRight: '10px' }}>
                                                                <Avatar src={wa} sx={{ width: 24, height: 24 }} />
                                                            </a>}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                                            {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(row.clone_balance)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                                            {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(row.balance)}
                                                        </Typography>
                                                    </TableCell>
                                                </>

                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                            <TableFooter>

                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        labelRowsPerPage="Строк на странице"
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={teams.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>

            }

            {rows === 0 && <Typography variant="h3" textAlign='center'>В структуре нет партнёров</Typography>}

        </>
    )
}

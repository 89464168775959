import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import { FC, useEffect } from 'react'

interface ITabImageComponent {
    data: any
}

export const TabYouTubeComponent: FC<ITabImageComponent> = ({ data }) => {
    return (
        <>
            <Box mb={3}>
                <Card>

                    <CardContent>
                        <Box>
                            <iframe width="560" height="315" src={data.link} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
                        </Box>

                        <Typography gutterBottom variant="h5" component="div">
                            {data.title}
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        </>
    )
}

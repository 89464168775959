import { Box, Typography } from "@mui/material"

export const EmptyMedia = () => {
    return (
        <>
            <Box py={5} width='100%'>
                <Typography variant="h4" component='h3' textAlign='center'>Контента в данной категории пока нет, но скоро будет!</Typography>
            </Box>
        </>
    )
}

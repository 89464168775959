import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'

interface ITabImageComponent {
    data: any
}

export const TabImageComponent: FC<ITabImageComponent> = ({ data }) => {
    const [open, setOpen] = useState(false);

    const handlepen = () => {
        setOpen(p => !p);
    };

    return (
        <>
            <Box mb={3}>
                <Card sx={{ px: 1 }}>
                    {/* <CardMedia
                        sx={{ minHeight: '400px', width: "auto", objectFit: 'contain' }}
                        image={data.link}
                        title={data.title}
                    /> */}
                    <CardContent sx={{ p: 0, position: "relative" }}>
                        <Box sx={{ filter: 'grayscale(.6)' }}>
                            <Avatar
                                sx={{ minHeight: '300px', width: '100%', borderRadius: 0, objectFit: 'cover', filter: 'blur(1px)' }}
                                src={data.link}
                            />
                        </Box>
                        <Typography mt={2} gutterBottom variant="h5" component="div">
                            {data.title}
                        </Typography>

                        {/* <Typography variant="body2" color="text.secondary">
                            Lizards are a widespread group of squamate reptiles, with over 6,000
                            species, ranging across all continents except Antarctica
                        </Typography> */}
                    </CardContent>
                    <CardActions>
                        <Button variant='contained' color='secondary' onClick={handlepen}>
                            Смотреть картинку
                        </Button>
                    </CardActions>
                </Card>
            </Box >


            <Dialog
                fullScreen
                open={open}
                onClose={handlepen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <IconButton onClick={handlepen}>
                        X
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    <img
                        src={data.link}
                        alt=''
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlepen}>Закрыть</Button>
                </DialogActions>
            </Dialog>


        </>
    )
}

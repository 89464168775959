import { Avatar, Box, Button, Card, CardContent, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import levelPlaceholder from '../../../../assets/images/x10level.png'
// import { useState } from 'react';
// import CloseIcon from '@mui/icons-material/Close';


export const EmptyCard = () => {
    // const [stateModal, setStateModal] = useState<boolean>(false);
    // const [JWClone, setJWClone] = useState<boolean>(false);

    // const getModalPayClone = (type: boolean) => {
    //     setJWClone(type);
    //     setStateModal(p => !p)
    // }

    return (
        <>
            <Box mb={3} sx={{ width: '100%' }} display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
                <Box display='flex' justifyContent='center' mb={1}>
                    <Chip sx={{ background: '#041ee9', color: "#fff", borderRadius: '6px', fontSize: "16px" }} label='0' />
                </Box>
                <Card sx={{ width: '100%', background: '#E1BBF8', border: '1px solid #1700CA', minHeight: '135px' }} >
                    <CardContent sx={{ background: '#E1BBF8', p: 0 }}>
                        <Typography textAlign="center" variant="h6" component='h3' sx={{ mb: 1 }}>
                            Место пустое
                        </Typography>
                        <Box display='flex' justifyContent='center'>
                            <Avatar
                                sx={{ width: '4rem', height: '4rem' }}
                                src={levelPlaceholder}
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Box>

            {/* 

            <Dialog
                open={stateModal}
                onClose={() => setStateModal(p => !p)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <>
                    <DialogTitle id="alert-dialog-title" display='flex' justifyContent='end'>
                        <IconButton sx={{ ml: 'auto' }} onClick={() => setStateModal(p => !p)} autoFocus>
                            <CloseIcon sx={{ fontWeight: 600, fontSize: '2rem', color: '#000' }} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>

                        <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' mt={3}>
                            <Typography variant="h3" mb={5} textAlign='center' sx={{ fontWeight: 500 }}>
                                Вы уверены что хотите приобрести {!JWClone ? "Клона" : "Ювелирного Клона"}?
                            </Typography>
                        </Box>


                        <Button color='error' size='large' fullWidth sx={{ fontSize: "20px" }}>
                            Да, я уверен!
                        </Button>
                    </DialogContent>
                    <DialogActions>
                        <Button color='error' variant='outlined' onClick={() => setStateModal(p => !p)}>
                            Отменить
                        </Button>
                    </DialogActions>

                </>


            </Dialog> */}


        </>
    )
}

import React, { FC } from 'react';
import { Navigate } from 'react-router-dom'
import { useSelector } from "react-redux";
import { RootState } from "src/redux/index";
import Spinner from 'src/views/spinner/Spinner';

interface RTLType {
    component: React.ReactNode;
}

export const PrivateRoute: FC<RTLType> = ({ component }): JSX.Element => {
    const app = useSelector((state: RootState) => state.app);
    const auth = app.auth;
    const load = app.load;

    if (!auth && load) {
        // return <PreloaderGlobal />
        return <Spinner />
    } else if (!auth && !load) return <Navigate to={`/login`} /> // поставить !auth
    else return <>{component}</>;
};

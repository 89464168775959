
import { FC, useEffect } from "react"
import { Avatar, Box, Button, Card, CardContent, Chip, IconButton, Typography } from '@mui/material'
import levelPlaceholder from '../../../../assets/images/x10level.png'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { Link } from "react-router-dom";

interface IUserCard {
    data: any
    place: string
    updateModalState: () => void
    updateDataModal: (data: any) => void
}

export const UserCard: FC<IUserCard> = ({ data, place, updateModalState, updateDataModal }) => {

    const openModal = (data: any) => {
        updateModalState();
        updateDataModal(data);
    }

    // useEffect(() => {
    //     console.log(data);
    // }, []);

    return (
        <>
            <Box mb={3} width='100%' display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
                <Box display='flex' justifyContent='center' mb={1}>
                    <Chip sx={{ background: '#041ee9', color: "#fff", borderRadius: '6px', fontSize: "16px" }} label={data.count ? data.count : "0"} />
                    {data.clones > 0 && <Chip sx={{ background: data.clones > 0 ? '#68EB9C' : "transparent", borderRadius: '6px', ml: 0.5 }}
                        label={data.clones > 0 ? `Клон ${data.clones}` : ""}
                    />
                    }
                </Box>
                <Card sx={{ background: '#E1BBF8', maxWidth: Number(place) > 1 ? "auto" : 420, p: 0, "& .MuiCardContent-root": { paddingBottom: 0 }, border: '1px solid #1700CA', minHeight: '135px' }}>
                    <CardContent sx={{ p: 0, background: '#E1BBF8' }}>
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <IconButton sx={{ p: 0.5 }} aria-label="Информация о пользователе"
                                onClick={() => openModal(data)}
                            >

                                {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="54"
                                    height="54"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    className="icon icon-tabler icons-tabler-outline icon-tabler-info-small"
                                    viewBox="0 0 24 24"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z"></path>
                                    <path d="M12 9h.01M11 12h1v4h1"></path>
                                </svg> */}

                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="34"
                                    fill="none"
                                    viewBox="0 0 8 9"
                                >
                                    <g clipPath="url(#clip0_72_4940)">
                                        <path
                                            fill="#514B4B"
                                            d="M5.166 7.254l-.1.412c-.304.12-.545.21-.725.273-.18.063-.39.094-.629.094-.366 0-.652-.09-.855-.268a.868.868 0 01-.305-.681c0-.107.007-.216.023-.328.015-.112.04-.238.074-.379l.378-1.339c.034-.128.062-.25.085-.364.023-.115.035-.22.035-.314 0-.171-.036-.29-.106-.358-.07-.067-.205-.101-.405-.101a1.06 1.06 0 00-.3.046 4.2 4.2 0 00-.266.087l.102-.413c.247-.101.484-.187.711-.26.227-.071.44-.107.643-.107.364 0 .645.088.842.264a.876.876 0 01.296.685c0 .059-.006.161-.02.308a2.056 2.056 0 01-.076.404l-.377 1.334a3.544 3.544 0 00-.12.679c0 .177.04.298.12.362.08.064.216.096.412.096.091 0 .195-.016.311-.048.116-.032.2-.06.252-.084zm.096-5.6a.78.78 0 01-.264.594.897.897 0 01-.634.245.907.907 0 01-.638-.245.779.779 0 01-.266-.594c0-.232.088-.431.266-.596A.903.903 0 014.364.81c.247 0 .459.083.634.248a.788.788 0 01.264.596z"
                                        ></path>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_72_4940">
                                            <path
                                                fill="#fff"
                                                d="M0 0H7.223V7.223H0z"
                                                transform="translate(.054 .81)"
                                            ></path>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </IconButton>
                            <Typography width='100%' sx={{ fontSize: '14px', fontWeight: '500', px: 1 }} component='h3' textAlign='center'>
                                {data.date}
                            </Typography>
                            {data.asc && <Box >
                                <IconButton
                                    component={Link}
                                    to={`?p=${data.asc.program}&l=${data.asc.level}&m=${data.asc.mentor_id}`}
                                    aria-label="Смотреть куда встанет"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="30"
                                        height="34"
                                        fill="none"
                                        viewBox="0 0 9 9"
                                    >
                                        <g fill="#514B4B" clipPath="url(#clip0_72_4951)">
                                            <path d="M8.062 4.287c-.657-.8-2.047-1.733-3.564-1.733-1.522 0-2.912.94-3.563 1.733a.212.212 0 000 .27C1.607 5.375 3 6.29 4.498 6.29c1.552 0 2.936-.968 3.564-1.734a.212.212 0 000-.269zm-2.12.135a1.445 1.445 0 01-2.886 0c0-.796.647-1.443 1.442-1.443.796 0 1.443.647 1.443 1.443zm-4.556 0c.24-.249.816-.785 1.625-1.13a1.863 1.863 0 000 2.258 5.037 5.037 0 01-1.625-1.128zm4.6 1.129a1.863 1.863 0 000-2.258c.615.26 1.187.673 1.624 1.129A5.13 5.13 0 015.985 5.55z"></path>
                                            <path d="M3.776 4.422a.723.723 0 101.446-.002.723.723 0 00-1.446.002zm1.02 0A.298.298 0 114.2 4.42a.298.298 0 01.596 0zM4.498 1.73a.212.212 0 00.213-.213v-.496a.212.212 0 00-.425 0v.496c0 .118.095.213.212.213zm1.268.284a.212.212 0 00.29-.08l.282-.495a.212.212 0 10-.369-.21l-.282.495a.212.212 0 00.079.29zm-2.825-.08a.212.212 0 10.37-.21l-.284-.496a.212.212 0 10-.369.21l.283.496zm1.557 5.178a.212.212 0 00-.212.212v.496a.212.212 0 00.425 0v-.496a.212.212 0 00-.213-.212zm-1.267-.285a.212.212 0 00-.29.08l-.283.495a.212.212 0 00.37.21l.282-.495a.212.212 0 00-.08-.29zm2.825.08a.212.212 0 00-.37.21l.283.496a.212.212 0 10.37-.21l-.283-.496z"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_72_4951">
                                                <path
                                                    fill="#fff"
                                                    d="M0 0H7.223V7.223H0z"
                                                    transform="translate(.887 .81)"
                                                ></path>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </IconButton>
                            </Box>}


                        </Box>

                        {/* {data.clones > 0 && */}
                        {/* <Box display='flex' justifyContent='center' mb={1}>
                           
                        </Box> */}


                        <Box
                            display='flex'
                            width={'100%'}
                            px={2}
                            alignItems='center'
                            justifyContent='center'
                        >
                            <Avatar
                                sx={{ width: { xs: '2.6rem', sm: "4rem" }, height: { xs: '2.6rem', sm: "4rem" }, mr: 1 }}
                                src={data.avatar ? data.avatar : levelPlaceholder}
                            />
                            <Box>
                                {/* <Typography
                                    sx={{ mt: 0, fontWeight: 400, fontSize: '15px', fontStyle: 'italic' }}
                                    component='h3' variant="h6" textAlign='center'>
                                    {data.name}
                                </Typography> */}

                                <Typography
                                    sx={{
                                        // mt: 1, 
                                        fontWeight: 600,
                                        fontSize: { xs: "15px", sm: "16px" }
                                    }}
                                    textAlign='center'
                                    component='h3' variant="h5">
                                    {data.login}
                                </Typography>

                                <Box width='100%' display='flex' justifyContent='center' mt={1}>

                                    {data.up > 0 &&
                                        <Button sx={{
                                            background: "#041ee97a"
                                        }} component={Link} to={`?p=${data.program}&l=${data.level}&m=${data.up}`} aria-label="Вверх по структуре">
                                            <KeyboardDoubleArrowUpIcon sx={{ color: '#FFF' }} />
                                        </Button>}

                                    {data.down > 0 &&
                                        <Button sx={{
                                            background: "#041ee97a"
                                        }} component={Link} to={`?p=${data.program}&l=${data.level}&m=${data.down}`} aria-label="В низ по структуре">
                                            <KeyboardDoubleArrowDownIcon sx={{ color: '#FFF' }} />
                                        </Button>}
                                </Box>

                            </Box>
                        </Box>



                        {/* <Typography

                            sx={{ mt: 0, color: '#7B7373', fontStyle: 'italic' }}
                            component='h3' variant="h6" textAlign='center'>
                            {data.name}
                        </Typography> */}

                    </CardContent>
                </Card>
            </Box >

        </>
    )
}

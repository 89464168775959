import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Scrollbar, Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../styles.css';
import s from '../s.module.scss'
import { Box, Container, Grid, Typography } from '@mui/material';

import slide1 from '../../../../../assets/images/sliders/home/slide-1.jpg'
import slide2 from '../../../../../assets/images/sliders/home/slide-2.jpg'
import slide3 from '../../../../../assets/images/sliders/home/slide-3.jpg'


export const SliderIndex = () => {
    return (
        <>
            <Swiper
                slidesPerView={1}
                centeredSlides={false}
                slidesPerGroupSkip={1}
                // grabCursor={true}
                keyboard={{
                    enabled: true,
                }}
                // breakpoints={{
                //     769: {
                //         slidesPerView: 1,
                //         slidesPerGroup: 2,
                //     },
                // }}
                scrollbar={true}
                navigation={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Keyboard, Scrollbar, Navigation, Pagination]}
                className="mySwiper"
            >
                <SwiperSlide style={{ borderRadius: 0 }}>
                    <Box className={s.slideContainer} sx={{ backgroundImage: `url(${slide1})` }}>
                    </Box>
                    <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} height='100%'>
                        <Grid container height='100%' display='flex' justifyContent='center' alignItems='end'>
                            <Grid item sx={{ mb: 3 }}>
                                <Typography textAlign='center' variant='h2' sx={{ color: '#fff', background: '#061DEA', p: 2 }}>
                                    БИЗНЕС - КЛУБ EASY
                                </Typography>
                                <Typography textAlign='center' variant='h2' sx={{ color: '#fff', p: 2 }}>
                                    ЭТАЛОН СОВРЕМЕННОГО БИЗНЕСА
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </SwiperSlide>
                <SwiperSlide style={{ borderRadius: 0 }}>
                    <Box className={s.slideContainer} sx={{ backgroundImage: `url(${slide2})` }}>
                    </Box>
                    <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} height='100%'>
                        <Grid container height='100%' display='flex' justifyContent='start' alignItems='center'>
                            <Grid item sx={{ mb: 0, pl: 5, pt: 5 }}>
                                <Container>
                                    <Typography textAlign='center' variant='h2' sx={{ mb: 2, color: '#fff', background: '#061DEA', p: 2 }}>
                                        С НАМИ:
                                    </Typography>
                                    <Box sx={{ pl: 2 }}>
                                        <Typography textAlign='center' variant='h6' sx={{ mb: 1, color: '#fff', background: '#061DEA', p: 1 }}>
                                            НАДЕЖНО
                                        </Typography>
                                        <Typography textAlign='center' variant='h6' sx={{ mb: 1, color: '#fff', background: '#061DEA', p: 1 }}>
                                            ИНТЕРЕСНО
                                        </Typography>
                                        <Typography textAlign='center' variant='h6' sx={{ mb: 1, color: '#fff', background: '#061DEA', p: 1 }}>
                                            ПРИБЫЛЬНО!
                                        </Typography>
                                    </Box>
                                </Container>
                            </Grid>
                        </Grid>
                    </Box >
                </SwiperSlide >
                <SwiperSlide style={{ borderRadius: 0 }}>
                    <Box className={s.slideContainer} sx={{ backgroundImage: `url(${slide3})` }}>
                    </Box>
                    <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} height='100%'>
                        <Grid container height='100%' display='flex' justifyContent='start' alignItems='center'>
                            <Grid item sx={{ mb: 0, pl: 5, pt: 5 }}>
                                <Container>
                                    <Typography textAlign='center' variant='h2' sx={{ mb: 2, color: '#fff', background: '#061DEA', p: 2 }}>
                                        БИЗНЕС - КЛУБ EASY
                                    </Typography>
                                    <Box sx={{ pl: 2 }}>
                                        <Typography textAlign='center' variant='h4' sx={{ mb: 1, color: '#fff', p: 1 }}>
                                            САМЫЙ СТРЕМИТЕЛЬНЫЙ ПУТЬ
                                            К БОЛЬШИМ ДЕНЬГАМ!

                                        </Typography>
                                    </Box>
                                </Container>
                            </Grid>
                        </Grid>
                    </Box >
                </SwiperSlide >
            </Swiper >
        </>
    )
}

import { FC } from "react"

interface INumberFormat {
    number: number
}

export const NumberFormat: FC<INumberFormat> = ({ number }) => {
    return (
        <>
            {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(number)}
        </>
    )
}

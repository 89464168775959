const { REACT_APP_NAME } = process.env;
import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material"
import { FormTransferMoney } from "../transfer/FormTransferMoney"
import { FormWithdrawBalance } from "./FormWithdrawBalance"
import PageContainer from "src/components/container/PageContainer"

export const WithdrawFromBalance = () => {
    return (
        <>
            <PageContainer title={`${REACT_APP_NAME} | Вывести со счёта`} description="Вывести со счёта">
                <Box sx={{ my: 5 }}>
                    <Card>
                        <CardHeader title="Вывести" />
                        <CardContent>
                            <Grid container display='flex' justifyContent='center'>
                                <Grid item xs={12} md={8} display='flex' justifyContent='center'>
                                    <FormWithdrawBalance />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </ PageContainer>

        </>
    )
}

import { Box, Button, FormLabel, InputLabel, List, ListItem, ListItemText, ListSubheader, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import { useSearchMarketingMutation } from 'src/redux/api/cabinet';
import { Alerting } from 'src/views/components_cabinet/Alerting';

export const SearchInStructure = () => {
    const [searchText, setSearchText] = useState<string>('');
    const [usersArr, setUsersArr] = useState<any[]>([]);
    // const [debouncedInputValue, setDebouncedInputValue] = React.useState("")
    const [inputValue, setInputValue] = React.useState("");
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);

    const [searchParams] = useSearchParams();
    const programParam = searchParams.get('p');
    const levelParam = searchParams.get('l');

    const handleInputChange = (t: string) => {
        setInputValue(t);
    }

    const debounceRequest = (t: string) => {
        if (t.length < 3) return false
        const body = {
            program: programParam,
            level: levelParam,
            login: t
        };

        userUpdateRequest(body);
    }

    const [userUpdateData, { isLoading, isSuccess }] = useSearchMarketingMutation();
    const userUpdateRequest = async (body: any) => {
        const jwt = localStorage.getItem('jwt');
        await userUpdateData({ body, jwt })
            .unwrap()
            .then((payload: any) => {
                // console.log('userUpdateData');
                // console.log(payload);
                setUsersArr(payload.data);
                // setgetAlert({ type: 1, msg: payload.message });
            })
            .catch((error: any) => {
                // console.log('err userUpdateData');
                // console.log(error);

                setgetAlert({ type: 2, msg: error.data.message });
            })
    }

    React.useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            debounceRequest(inputValue);
        }, 2000);
        return () => clearTimeout(delayInputTimeoutId);
    }, [inputValue, 2000]);

    return (
        <>
            {getAlert && <Alerting get={getAlert} />}
            <Box width='100%'>
                {/* <InputLabel sx={{ fontWeight: 600 }}>
                    ПОИСК ПО ЛОГИНУ
                </InputLabel> */}
                <FormLabel sx={{ fontSize: "16px", fontWeight: 600 }}>
                    Поиск по логину
                </FormLabel>
                <TextField fullWidth
                    disabled={isLoading}
                    // label="Логин"
                    variant="outlined"
                    onInput={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.value)}
                />

                {isLoading && <Typography mt={1}>Обрабатываю...</Typography>}

                {usersArr.length > 0 && isSuccess && <>
                    <Box width='100%' position='absolute'>
                        <List
                            sx={{
                                p: 0,
                                width: '100%',
                                maxWidth: 360,
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 300,
                                '& ul': { padding: 0 },
                                background: "#ededed"
                            }}
                            subheader={<li />}
                        >
                            {usersArr.map((el, i) => (
                                <li key={i}>
                                    <ul>
                                        <ListItem>
                                            <ListItemText
                                                primary={
                                                    <Box>
                                                        <Button fullWidth sx={{ textAlign: "start" }} component={Link} to={`?p=${el.program}&l=${el.level}&m=${el.id}`}>
                                                            {el.login} {el.clones > 0 && `(Клон ${el.clones})`}
                                                        </Button>
                                                    </Box>
                                                } />
                                        </ListItem>
                                    </ul>
                                </li>
                            ))}
                        </List>

                    </Box>
                </>}

            </Box>

        </>
    )
}

const { REACT_APP_NAME } = process.env;

import { Box, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import PageContainer from "src/components/container/PageContainer";
import { useGetNotificationMutation } from "src/redux/api/cabinet";
import { Alerting } from "src/views/components_cabinet/Alerting";
// import { PageUnderDev } from "../page-under-dev/PageUnderDev"
import dayjs from "dayjs";


export const AlertOfficePage = () => {

    const [rows, setRows] = useState<any>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);

    const [listNotify, setListNotify] = useState<any[]>([]);
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);

    const [userUpdateData, { isLoading, isError, isSuccess }] = useGetNotificationMutation();
    const userUpdateRequest = async () => {
        const jwt = localStorage.getItem('jwt');
        await userUpdateData({ jwt })
            .unwrap()
            .then((payload: any) => {
                setListNotify(payload.data);
                // console.log('userUpdateData');
                // console.log(payload);
                // dispatch(updProfileData(payload.data));
                // setgetAlert({ type: 1, msg: payload.message });
            })
            .catch((error: any) => {
                // console.log('err userUpdateData');
                // console.log(error);

                setgetAlert({ type: 2, msg: error.data.message });
            })
    }

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        userUpdateRequest();
    }, [])

    return (
        <>
            {/* <PageUnderDev /> */}
            {getAlert && <Alerting get={getAlert} />}

            <PageContainer title={`${REACT_APP_NAME} | Переводы`} description="Перевод средств на другой аккаунт">
                <Box sx={{ my: 5 }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">№</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h6">Описание</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h6">Дата</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listNotify && listNotify
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row: any, i: number) => {
                                        return (
                                            <TableRow hover key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                                <TableCell component="th" scope="row">
                                                    <Typography>{i + 1}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                                                        {row.text}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '14px' }}>
                                                        {dayjs(row.created_at).format('DD.MM.YYYY')}
                                                    </Typography>
                                                    <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '14px' }}>
                                                        {dayjs(row.created_at).format('HH:mm:ss')}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }

                                {isLoading &&
                                    <>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell align="center" colSpan={7}>
                                                <Skeleton height={'40px'} variant="rounded" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell align="center" colSpan={7}>
                                                <Skeleton height={'40px'} variant="rounded" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell align="center" colSpan={7}>
                                                <Skeleton height={'40px'} variant="rounded" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell align="center" colSpan={7}>
                                                <Skeleton height={'40px'} variant="rounded" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell align="center" colSpan={7}>
                                                <Skeleton height={'40px'} variant="rounded" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell align="center" colSpan={7}>
                                                <Skeleton height={'40px'} variant="rounded" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell align="center" colSpan={7}>
                                                <Skeleton height={'40px'} variant="rounded" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell align="center" colSpan={7}>
                                                <Skeleton height={'40px'} variant="rounded" />
                                            </TableCell>
                                        </TableRow>
                                    </>
                                }

                                {rows.length === 0 && isSuccess &&
                                    <TableRow
                                        // onClick={() => updPersonInfoIdLocal(row.id)}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                    >
                                        <TableCell align="center" colSpan={7}>
                                            <Typography variant="h6" textAlign='center'>Уведомления не найдены</Typography>
                                        </TableCell>
                                    </TableRow>
                                }

                                {isError && <TableRow
                                    // onClick={() => updPersonInfoIdLocal(row.id)}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                >
                                    <TableCell align="center" colSpan={7}>
                                        <Typography variant="h6" textAlign='center'>Ошибка при загрузке уведомлений</Typography>
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                            {/* <TableFooter>

                            </TableFooter> */}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        labelRowsPerPage="Строк на странице"
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </PageContainer >

        </>
    )
}

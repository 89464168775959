import { Box, Grid, Theme, Typography, useMediaQuery } from '@mui/material'
import whyweindex from '../../../../../assets/images/landing/whyweindex.jpg'

export const WhyWeIndex = () => {
    const md = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <>
            <Box id="why_we" sx={{ backgroundImage: `url(${whyweindex})`, backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '580px', borderRadius: '0px', height: '100%' }}>
                <Grid container display='flex' justifyContent='space-between'>
                    <Grid item xs={12} md={6}>
                        <Typography component='h2' variant='h1' textAlign='center' sx={{ color: '#fff', fontWeight: 500, mt: 5 }}>ПОЧЕМУ МЫ?</Typography>
                    </Grid>

                    <Grid item xs={12} md={5} display='inline-flex' alignItems='center' sx={{ minHeight: '450px' }}>
                        <Box >
                            <Typography color='#fff' sx={{ p: 1, borderRadius: '6px', background: '#061DEA', maxWidth: md ? '70%' : '100%' }}>
                                Бизнес клуб EASY - команда опытных и
                                успешных профессионалов.
                                Однажды каждый из нас уже прошел этот путь.
                                Поможем, научим и доведём до результата.
                                Мы не обещаем - мы воплощаем!
                                Да.... и МЫ ИСКРЕННЕ ЖЕЛАЕМ ВАМ УСПЕХА!!!
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

const { REACT_APP_CABINET, REACT_APP_NAME, REACT_APP_REF_LINK } = process.env;
import { Box, Button, Card, CardActions, CardContent, Grid, Skeleton, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "src/store/Store";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PageContainer from "src/components/container/PageContainer";
import { NumberFormat } from "src/components/hooks/number-format/NumberFormat";
import { useHomeStatisticsMutation } from "src/redux/api/cabinet";

export const HomePageOffice = () => {
    const { data } = useSelector((state: AppState) => state.app);
    const [copied, setCopied] = useState<boolean>(false);
    const [dataStat, setDataStat] = useState<any>(undefined);


    const copyTextToClipboard = async (text: string) => {
        try {
            await navigator.clipboard.writeText(text);
            setCopied(true)
            setTimeout(() => {
                setCopied(false)
            }, 3000);
        } catch (err) {
            console.error('Ошибка:', err);
        }
    };

    const [eventData, { isLoading }] = useHomeStatisticsMutation();
    const eventRequest = async () => {
        const jwt = localStorage.getItem('jwt');
        await eventData({ jwt })
            .unwrap()
            .then((payload: any) => {
                // console.log('eventData');
                // console.log(payload);
                setDataStat(payload.data);
                // setgetAlert({ type: 1, msg: payload.message });
            })
            .catch((error: any) => {
                // console.log('err eventData');
                // console.log(error);

                // setgetAlert({ type: 2, msg: error.data.message });
            })
    }

    useEffect(() => {
        eventRequest();
    }, []);

    return (
        <>
            <PageContainer title={`${REACT_APP_NAME} | Главная`} description="Главная страница кабинета">
                <Box sx={{ my: 5 }}>
                    <Card sx={{ mb: 2 }}>
                        <CardContent sx={{ px: 0 }}>
                            <Typography sx={{ fontSize: 18, p: 0 }} variant="h3" color="text.secondary" gutterBottom>
                                Моя реферальная ссылка:  <Typography color='primary' sx={{ fontWeight: 600 }} component='span'>{REACT_APP_CABINET}invited/{data.login}</Typography>
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {!copied && <Button variant="contained" color="primary"
                                onClick={() => copyTextToClipboard(`${REACT_APP_REF_LINK}${data.login}`)}
                            >
                                Копировать
                            </Button>}

                            {copied && <Button variant="outlined" color="success" startIcon={<DoneAllIcon />}>
                                Успешно скопировано
                            </Button>}
                        </CardActions>
                    </Card>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} display='flex'>
                            <Card sx={{ mb: 2, display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: "center" }} >
                                <Box>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="61"
                                        height="61"
                                        fill="none"
                                        viewBox="0 0 21 21"
                                    >
                                        <path
                                            fill="#E1BBF8"
                                            fillRule="evenodd"
                                            d="M7.875 18.594a5.469 5.469 0 115.242-3.906.657.657 0 001.258.374 6.781 6.781 0 10-2.624 3.628.658.658 0 00-.256-1.193.658.658 0 00-.495.117 5.441 5.441 0 01-3.125.98zm8.286-17.308a.656.656 0 01.928 0l2.625 2.625a.656.656 0 01-.928.928l-1.505-1.505V8.75a.656.656 0 01-1.312 0V3.334l-1.505 1.505a.657.657 0 11-.928-.928l2.625-2.625z"
                                            clipRule="evenodd"
                                        ></path>
                                        <path
                                            fill="#E1BBF8"
                                            fillRule="evenodd"
                                            d="M6.344 12.134c0-.78.634-1.415 1.416-1.415h1.428a.656.656 0 110 1.312H7.76a.103.103 0 00-.098.074.104.104 0 00.04.116l1.073.716a1.417 1.417 0 01-.785 2.594H6.562a.656.656 0 010-1.312H7.99a.104.104 0 00.057-.19l-1.072-.716a1.416 1.416 0 01-.631-1.179z"
                                            clipRule="evenodd"
                                        ></path>
                                        <path
                                            fill="#E1BBF8"
                                            fillRule="evenodd"
                                            d="M7.875 14.219a.656.656 0 01.656.656v1.75a.656.656 0 11-1.312 0v-1.75a.656.656 0 01.656-.656zm0-5.25a.656.656 0 01.656.656v1.75a.656.656 0 11-1.312 0v-1.75a.656.656 0 01.656-.656z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </Box>
                                <Box>
                                    <Typography variant="h6" textAlign='center' sx={{ color: '#7B7B7B', mb: 1 }}>Заработано</Typography>
                                    <Typography variant="h4" textAlign='center' >
                                        {dataStat ? <NumberFormat number={dataStat.dohod} /> : <Skeleton variant="rounded" width={100} height={30} sx={{ background: "#e1bbf8" }} />}
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} display='flex'>
                            <Card sx={{ mb: 2, display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: "center" }} >
                                <Box>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="60"
                                        // height="21"
                                        fill="none"
                                        viewBox="0 0 19 21"
                                    >
                                        <path
                                            fill="#E1BBF8"
                                            d="M16.13 8.393H8.309c.362-.415.581-.958.581-1.551a2.37 2.37 0 00-2.367-2.367 2.37 2.37 0 00-2.367 2.367c0 .593.22 1.136.582 1.551H2.869A2.049 2.049 0 00.823 10.44v2.415a.41.41 0 00.818 0V10.44c0-.677.551-1.228 1.228-1.228h11.46c.678 0 1.229.55 1.229 1.228v2.21H11.3a2.049 2.049 0 00-2.047 2.047c0 1.128.918 2.046 2.047 2.046h4.257v2.21a1.23 1.23 0 01-1.228 1.228H2.87a1.23 1.23 0 01-1.229-1.228v-2.418a.41.41 0 10-.818 0v2.418C.823 20.082 1.74 21 2.869 21h13.262a2.049 2.049 0 002.046-2.047V10.44a2.049 2.049 0 00-2.046-2.047zm-9.608-3.1a1.55 1.55 0 011.549 1.55A1.55 1.55 0 016.522 8.39a1.55 1.55 0 01-1.548-1.549 1.55 1.55 0 011.548-1.548zm9.444 3.919h.165c.677 0 1.228.55 1.228 1.228v2.21h-.983v-2.21c0-.46-.152-.886-.41-1.228zm-5.893 5.485c0-.677.55-1.228 1.228-1.228h6.058v2.456H11.3a1.23 1.23 0 01-1.228-1.228zm6.058 5.484h-.165c.258-.342.41-.767.41-1.228v-2.21h.983v2.21a1.23 1.23 0 01-1.228 1.228z"
                                        ></path>
                                        <path
                                            fill="#E1BBF8"
                                            d="M11.196 14.287a.412.412 0 00-.29.699.413.413 0 00.58 0 .412.412 0 000-.579.413.413 0 00-.29-.12zm1.282-8.652a2.37 2.37 0 002.367-2.368A2.37 2.37 0 0012.478.9a2.37 2.37 0 00-2.367 2.367 2.37 2.37 0 002.367 2.368zm0-3.916a1.55 1.55 0 011.548 1.548 1.55 1.55 0 01-1.548 1.549 1.55 1.55 0 01-1.549-1.549 1.55 1.55 0 011.549-1.548zM8.48 4.035a.41.41 0 00.41-.41V.41a.41.41 0 10-.82 0v3.217c0 .226.184.409.41.409zM6.515 2.62a.41.41 0 00.41-.41V.41a.41.41 0 10-.819 0v1.8c0 .226.183.41.41.41zM1.232 15.106a.412.412 0 00.41-.41.412.412 0 00-.41-.409.412.412 0 00-.41.41.412.412 0 00.41.409z"
                                        ></path>
                                    </svg>
                                </Box>
                                <Box>
                                    <Typography variant="h6" textAlign='center' sx={{ color: '#7B7B7B', mb: 1 }}>Баланс</Typography>
                                    <Typography variant="h4" textAlign='center' >
                                        {dataStat ? <NumberFormat number={dataStat.balance} /> : <Skeleton variant="rounded" width={100} height={30} sx={{ background: "#e1bbf8" }} />}
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} display='flex'>
                            <Card sx={{ mb: 2, display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: "center" }} >
                                <Box>

                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="60"
                                        // height="21"
                                        fill="none"
                                        viewBox="0 0 21 21"
                                    >
                                        <g fill="#E1BBF8" clipPath="url(#clip0_36_166)">
                                            <path d="M20.045 10.588V7.637c0-1.001-.776-1.815-1.757-1.894L15.548.955a1.886 1.886 0 00-2.592-.698L3.56 5.727H1.91C.855 5.727 0 6.584 0 7.637V19.09C0 20.144.856 21 1.91 21h16.226c1.053 0 1.91-.856 1.91-1.91v-2.95A1.43 1.43 0 0021 14.794v-2.863c0-.622-.4-1.146-.955-1.344zm-2.866-4.86h-4.16l3.12-1.817 1.04 1.816zm-1.514-2.645l-4.543 2.644H9.238l5.956-3.467.471.823zm-2.229-2a.936.936 0 011.282.348h.002L7.34 5.728H5.458l7.98-4.645zm5.655 18.008a.956.956 0 01-.955.954H1.91a.956.956 0 01-.954-.954V7.637c0-.527.428-.955.954-.955h16.227c.527 0 .955.428.955.955V10.5h-2.864a2.867 2.867 0 00-2.863 2.864 2.867 2.867 0 002.863 2.863h2.864v2.864zm.954-4.296a.478.478 0 01-.477.478h-3.34a1.911 1.911 0 01-1.91-1.91c0-1.052.856-1.908 1.91-1.908h3.34c.263 0 .477.214.477.477v2.864z"></path>
                                            <path d="M16.227 12.41a.956.956 0 000 1.91.956.956 0 000-1.91z"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_36_166">
                                                <path fill="#fff" d="M0 0H21V21H0z"></path>
                                            </clipPath>
                                        </defs>
                                    </svg>

                                </Box>
                                <Box>
                                    <Typography variant="h6" textAlign='center' sx={{ color: '#7B7B7B', mb: 1 }}>Доступно для вывода</Typography>
                                    <Typography variant="h4" textAlign='center' display='flex' justifyContent='center'>
                                        {dataStat ? <NumberFormat number={dataStat.dostupno} /> : <Skeleton variant="rounded" width={100} height={30} sx={{ background: "#e1bbf8" }} />}
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} display='flex'>
                            <Card sx={{ mb: 2, display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: "center" }} >
                                <Box>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="61"
                                        // height="21"
                                        fill="none"
                                        viewBox="0 0 21 21"
                                    >
                                        <g fill="#E1BBF8" clipPath="url(#clip0_36_173)">
                                            <path d="M10.5 0C4.71 0 0 4.71 0 10.5S4.71 21 10.5 21 21 16.29 21 10.5 16.29 0 10.5 0zm0 19.47c-4.946 0-8.97-4.023-8.97-8.97 0-4.946 4.024-8.97 8.97-8.97 4.947 0 8.97 4.024 8.97 8.97 0 4.947-4.023 8.97-8.97 8.97z"></path>
                                            <path d="M3.256 16.687L16.662 3.28l1.08 1.081L4.338 17.768l-1.08-1.081z"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_36_173">
                                                <path fill="#fff" d="M0 0H21V21H0z"></path>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </Box>
                                <Box>
                                    <Typography variant="h6" textAlign='center' sx={{ color: '#7B7B7B', mb: 1 }}>Выведено</Typography>
                                    <Typography variant="h4" textAlign='center' >
                                        {dataStat ? <NumberFormat number={dataStat.limit} /> : <Skeleton variant="rounded" width={100} height={30} sx={{ background: "#e1bbf8" }} />}
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>

                </Box>
            </PageContainer>
        </>
    )
}
